import classNames from 'classnames';
import React from 'react';
import { editDateToFull, editTime } from '../../../../../../utils/dateEditor';
import { editNameWithoutDots } from '../../../../../../utils/nameEditor';
import styles from './messageitem.module.css';
import aiAvatar from '../../../../../../assets/images/chat/ai.webp'
import supAvatar from '../../../../../../assets/images/chat/sup.webp'

export default function MessageItem(props) {

  return (
    <div
      className={
        classNames(
          'flex',
          props.my && styles.right,
          (props.ai || props.support) && styles.left,
        )
      }
    >
      <div className={classNames(
        'flex',
        styles.messageBlock,
        props.my && styles.myMessage,
        (props.ai || props.support) && styles.serverMessage,
      )}>
        {
          !props.system && props.my
            ?
            <div
              className={classNames('flex', 'imageBox', styles.avatar)}
              style={{ backgroundColor: `${props.me.user.avatar_color}` }}
            >
              {(props.me.user.avatar && props.my) ?
                <img src={props.me.user.avatar} alt="ava" />
                :
                <p className={styles.initial}>
                  {editNameWithoutDots(props.me.user.last_name)}{editNameWithoutDots(props.me.user.first_name)}
                </p>
              }
            </div>
            : !props.system && props.ai ?
              <div
                className={classNames('flex', 'imageBox', styles.avatar)}
              >
                <img src={aiAvatar} alt="ava" />
              </div>
              : !props.system && props.support ?
                <div
                  className={classNames('flex', 'imageBox', styles.avatar)}
                >
                  <img src={supAvatar} alt="ava" />
                </div>
                : null
        }
        <div className={classNames(
          'flex',
          !props.system && styles.message,
          props.system && styles.system,
          props.support && styles.support
        )}>
          <span>{props.content}</span>
          {
            !props.system &&
            <p className={styles.time}>
              {editDateToFull(props.time) != editDateToFull(new Date) ? editDateToFull(props.time) : null}
              &nbsp;
              {editTime(props.time)}
            </p>
          }
        </div>
      </div>
    </div>
  );
}
