import classNames from 'classnames';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { socket } from '../../../../../../../../../API/socket';
import AddIcon from '../../../../../../../../../svgIcons/AddIcon';
import EditIcon from '../../../../../../../../../svgIcons/EditIcon';
import UsersPopupWrapper from '../TaskPopups/UsersPopup/UsersPopupWrapper';
import styles from './taskbody.module.css';
import TaskUser from './TaskUsers/TaskUser';

export default function TaskBody(props) {

  const [editName, setEditName] = useState(false)
  const [taskName, setTaskName] = useState(props.name);
  const [isOpenUserPopup, setIsOpenUserPopup] = useState(false);

  useEffect(() => {
    setTaskName(props.name)
  }, [props.name])

  const handleClickOpenUser = (e) => {
    e.stopPropagation()
    setIsOpenUserPopup(true);
    document.body.classList.add('modal-show');
  }

  const handleClickClosePopup = () => {
    isOpenUserPopup && setIsOpenUserPopup(false);
    document.body.classList.remove('modal-show');
  }

  const onSubmit = () => {
    taskName.trim() && setEditName(false);
    (taskName != props.name) && socket.emit('ChangeTaskName', { 'taskId': props.taskId, 'name': taskName })
  }

  return (
    <div className={classNames('flex', styles.taskBody, props.paddingRight && styles.paddingRight)}>
      <div className={classNames('flex', styles.taskUsers)}>
        {props.users?.length > 0 &&
          props.users.map((item, index) =>
            <TaskUser
              key={item.user?.id}
              user={item}
              toTop={index}
            />
          )
        }
        <button
          className={classNames('flex', 'iconBtn', styles.addUser)}
          onClick={handleClickOpenUser}
        >
          <AddIcon />
        </button>
      </div>
      {
        !editName ?
          <div className={styles.taskName}>
            <pre
              onClick={(e) => {
                e.stopPropagation()
                setEditName(true)
              }}
            >
              {props.name}
            </pre>
          </div>
          :
          <div
            onClick={(e) => e.stopPropagation()}
            className={classNames('areaWrapper', styles.textWrapper)}
          >
            <p className={'hiddenText'}>
              {taskName} <br />
              текст
            </p>
            <textarea
              autoFocus={true}
              onFocus={(e) => e.target.setSelectionRange(taskName.length, taskName.length)}
              className={classNames('input', 'resizeArea')}
              name="task"
              placeholder='Введите название'
              defaultValue={taskName}
              onChange={(e) => setTaskName(e.target.value)}
              onBlur={onSubmit}
            >
            </textarea>
          </div>
      }
      {
        isOpenUserPopup &&
        <div
          onClick={(e) => e.stopPropagation()}
        >
          <UsersPopupWrapper
            project={props.project}
            taskId={props.taskId}
            users={props.users}
            handleClickClose={handleClickClosePopup}
          />
        </div>
      }
    </div>
  );
}
