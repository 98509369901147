import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './login.module.css';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { login } from '../../redux/authReducer';
import hideImg from '../../assets/images/hidePass.png'
import showImg from '../../assets/images/showPass.png'
import LogoIcon from '../../svgIcons/Logo';
import TGIcon from '../../svgIcons/TGIcon';
import russia from '../../assets/images/Russia.png'
import OfferPopup from './OfferPopup/OfferPopup';

localStorage.setItem('Tsets', "test")

export default function Login(props) {

  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const {
    clearErrors,
    setError,
    register,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const onSubmit = (data => {
    dispatch(login(data.email, data.password, setError));
  })

  return (
    <div className={styles.loginPage}>
      <div className={classNames('container', 'flex', styles.loginTop)}>
        <a href='http://boom-track.ru' target="_blank">
          <LogoIcon />
        </a>
        <div className={classNames('flex', styles.linksBox)}>
          <a href="https://t.me/boom_track_support" className={'flex'} target="_blank">
            <TGIcon />
          </a>
          <a href="#" className={classNames('flex', styles.lang)}>
            <img src={russia} alt="" />
          </a>
        </div>
      </div>
      <div className={classNames('flex', styles.formContainer)}>
        <div className={classNames('flex', styles.formWrapper)}>
          <div className={styles.formBox}>
            <h1 className={styles.formName}>Войдите, чтобы продолжить</h1>
            <form
              className={classNames('flex', styles.form)}
              onSubmit={handleSubmit(onSubmit)}
              onChange={() => {
                clearErrors('serverError');
              }
              }
            >
              <div className={classNames('flex', styles.inputWrapper)}>
                <label htmlFor="Email" className={styles.label}>
                  Email
                </label>
                <div className={!errors.email
                  ? styles.inputBox
                  : classNames(styles.inputBox, styles.boxError)}>

                  <input
                    className={!errors.email
                      ? styles.input
                      : classNames(styles.input, styles.error)}
                    type='text'
                    name='Email'
                    id='Email'
                    placeholder='Email'
                    {...register('email',
                      {
                        required: 'Введите e-mail',
                      })}
                  />
                  {errors.email && <div className={styles.errorMessage}>{errors.email.message}</div>}
                </div>
              </div>
              <div className={classNames('flex', styles.inputWrapper)}>
                <label htmlFor="password" className={styles.label}>
                  Пароль
                </label>
                <div className={!errors.password
                  ? styles.inputBox
                  : classNames(styles.inputBox, styles.boxError)}>

                  <input
                    className={!errors.password
                      ? styles.input
                      : classNames(styles.input, styles.error)}
                    type={visible ? 'text' : 'password'}
                    name='password'
                    placeholder='Пароль'
                    {...register('password',
                      {
                        required: 'Введите пароль',
                      })}
                  />
                  <button
                    className={classNames('imageBox', styles.passBtn)}
                    type='button'
                    onClick={() => {
                      visible ? setVisible(false) : setVisible(true)
                    }}>
                    <img src={visible ? hideImg : showImg} alt="icon" />
                  </button>
                  {errors.password && <div className={styles.errorMessage}>{errors.password.message}</div>}
                </div>
              </div>
              <div>
                <input
                  className={classNames(styles.formBtn, styles.btnSubmit)}
                  type='submit'
                  value='Войти'
                  disabled={!isValid}
                />
              </div>
              <div>
                <a
                  className={classNames(styles.formBtn, styles.btnSubmit)}
                  href="https://boom-track.ru/signup/"
                >
                  Зарегистрироваться
                </a>
              </div>
            </form>
            {errors.serverError && <div className={styles.errorForm}>{errors.serverError.message}</div>}
          </div>
          <div>
            <div className={styles.banner}>
              <h2 className={styles.bannerTitle}>Станьте одним из первых!</h2>
              <p className={styles.bannerText}>
                Оставьте свои контактные данные,
                чтобы мы могли провести для вас
                демонстрацию нашей системы и предложить вам
                стать нашим первым клиентом с скидкой. <br />
                Мы готовы к сотрудничеству и ждем вас!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
