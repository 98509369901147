import classNames from 'classnames';
import React, { useState } from 'react';
import ColumnHeader from '../Column/ColumnHeader/ColumnHeader';
import styles from './archivedcolumns.module.css';


export default function ArchivedColumns(props) {

  const [isOpenList, setIsOpenList] = useState(false)

  const handleOpen = () => {
    setIsOpenList(!isOpenList)
  }

  return (
    <div className={styles.archivedColsWrapper}>
      <button
        onClick={handleOpen}
        className={classNames('iconBtn', styles.arhiveBtn)}
      >
        Архив колонок
      </button>
      {
        isOpenList &&
        <div className={classNames('flex', styles.archivedColsList)}>
          {
            props.archived?.map(item =>
              <ColumnHeader
                state={{name: 'Архив'}}
                title={item.name}
                colId={item.id}
                archived={true}
              />
            )
          }
        </div>
      }
    </div>
  );
}
