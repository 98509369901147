import React, { useState } from 'react';
import styles from './datepick.module.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ru } from 'date-fns/locale/ru';
import CalendarIcon from '../../../svgIcons/CalendarIcon';
import classNames from "classnames";
import InputMask from 'react-input-mask';
import { useEffect } from 'react';

export default function DatePick(props) {

  const now = new Date()

  const [startDate, setStartDate] = useState(props.range ? new Date((now).setMonth(now.getMonth() - 1)) : props.startDate)
  const [endDate, setEndDate] = useState(new Date())

  const handleRangeChange = ([newStartDate, newEndDate]) => {
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  const handleDefaultChange = (date) => {
    setStartDate(date);
    props.inTask && props.setDate(date)
  };

  return (
    <DatePicker
      locale={ru}
      showIcon
      icon={<CalendarIcon />}
      selectsRange={props.range}
      selected={!props.range && startDate}
      startDate={startDate}
      endDate={props.range && endDate}
      onChange={props.range ? handleRangeChange : handleDefaultChange}
      dateFormat="dd.MM.yyyy"
      onCalendarClose={() => props.range ? props.handlePickDate(startDate, endDate) : props.handlePickDate(startDate || '')}
      calendarStartDay={1}
      placeholderText="дд.мм.гггг"
      openToDate={startDate || new Date()}
      autoFocus={props.autofocus}
      className={classNames('pickerDate', props.error && 'pickerError', props.welcome && styles.welcomeInput)}
      customInput={!props.range &&
        <InputMask
          mask="99.99.9999"
        // onInput={e => {
        //   setStartDate(null)
        //   console.log(e.target.value?.replace(/\*|-|_|\s|\$/g, '').replace(/\.\s*$/, ''))
        // }}
        />
      }
    />
  );
}
