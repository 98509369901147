import React from 'react';
import styles from './commentfiles.module.css';
import FileCardList from '../../../../FileCardList/FileCardList';

export default function CommentFiles(props) {
  return (
    <div className={styles.filesWrapper}>
      <FileCardList
        files={props.filesList}
        withoutMenu={true}
        editFile={props.editFile}
        handleDeleteFile={props.handleDeleteFile}
        insideParent={props.insideParent}
      />
    </div>
  );
}
