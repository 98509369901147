export function editFileName(string) {
  return string?.slice(string.lastIndexOf('.'))
}

export function editLongFileName(string) {
  if (string?.slice(0, string.lastIndexOf('.')).length > 13) {
    return `${string?.slice(0, 9)}` + `...` + `${string?.slice(string.lastIndexOf('.'))}`
  } else {
    return string;
  }
}
