import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { socket } from '../../../../../../../../../../API/socket';
import { selectLabels } from '../../../../../../../../../../redux/BoardPage/сolumnSelector';
import { getLabels } from '../../../../../../../../../../redux/BoardPage/сolumnsReducer';
import styles from './tagslistpopup.module.css';

export default function TagsListPopup(props) {

  const refList = useRef(null)

  const outsideHandleClick = (e) => {
    if (refList.current && !refList.current.contains(e.target)) {
      props.handleClickClose(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', outsideHandleClick);
    return () => { }
  }, [])

  let { boardId } = useParams();
  const allTagsList = useSelector(selectLabels);
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getLabels(boardId))
  }, [])

  const addTagToTask = (id) => {
    socket.emit('AddLabel', {
      'taskId': props.taskId,
      'labelId': id,
    })
  }

  return (
    <div className={classNames('flex', 'shadow', styles.list)} ref={refList}>
      {allTagsList.map((item, index) =>
        <span
          key={item.id + index}
          style={{ 'backgroundColor': `${item.color}` }}
          className={classNames('tag', Boolean(item.color) && 'tagWhite', 'nowrapString', styles.tag)}

          onClick={() => addTagToTask(item.id)}
        >
          #{item.name}
        </span>
      )}
    </div>
  );
}
