import React from 'react';

export default function SendIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2261_171)">
        <path d="M18.2635 7.2725L3.30429 0.235C2.45929 -0.175833 1.46345 -0.0341666 0.764288 0.594167C0.0634546 1.225 -0.179879 2.205 0.143455 3.09C0.157621 3.12583 3.81929 10.0033 3.81929 10.0033C3.81929 10.0033 0.225121 16.8792 0.212621 16.9142C-0.109879 17.8 0.135955 18.7783 0.836788 19.4083C1.27095 19.7975 1.81929 19.9983 2.37095 19.9983C2.71179 19.9983 3.05345 19.9217 3.37179 19.7658L18.2651 12.7342C19.336 12.2317 20.001 11.185 20.0001 10.0025C20.0001 8.81917 19.3326 7.7725 18.2635 7.2725ZM1.69345 2.4725C1.59179 2.12667 1.80845 1.8975 1.87929 1.8325C1.95345 1.76667 2.22429 1.5625 2.57762 1.73583C2.58179 1.7375 17.556 8.78167 17.556 8.78167C17.7551 8.875 17.921 9.00667 18.0485 9.16667H5.26262L1.69345 2.4725ZM17.5551 11.2258L2.64845 18.2642C2.29429 18.4383 2.02429 18.235 1.95012 18.1675C1.87845 18.1042 1.66179 17.8733 1.76429 17.5267L5.26595 10.8333H18.0535C17.926 10.9958 17.7568 11.1308 17.5551 11.2258Z" fill="#3E5196" />
      </g>
      <defs>
        <clipPath id="clip0_2261_171">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
