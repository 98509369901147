import React from 'react';

export default function PauseIcon(props) {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2044_255)">
        <path d="M4.875 0C4.17881 0 3.51113 0.276562 3.01884 0.768845C2.52656 1.26113 2.25 1.92881 2.25 2.625V15.375C2.25 16.0712 2.52656 16.7389 3.01884 17.2312C3.51113 17.7234 4.17881 18 4.875 18C5.57119 18 6.23887 17.7234 6.73116 17.2312C7.22344 16.7389 7.5 16.0712 7.5 15.375V2.625C7.5 1.92881 7.22344 1.26113 6.73116 0.768845C6.23887 0.276562 5.57119 0 4.875 0ZM6 15.375C6 15.6734 5.88147 15.9595 5.6705 16.1705C5.45952 16.3815 5.17337 16.5 4.875 16.5C4.57663 16.5 4.29048 16.3815 4.0795 16.1705C3.86853 15.9595 3.75 15.6734 3.75 15.375V2.625C3.75 2.32663 3.86853 2.04048 4.0795 1.8295C4.29048 1.61853 4.57663 1.5 4.875 1.5C5.17337 1.5 5.45952 1.61853 5.6705 1.8295C5.88147 2.04048 6 2.32663 6 2.625V15.375Z" fill="#3E5196" />
        <path d="M13.125 0C12.4288 0 11.7611 0.276562 11.2688 0.768845C10.7766 1.26113 10.5 1.92881 10.5 2.625V15.375C10.5 16.0712 10.7766 16.7389 11.2688 17.2312C11.7611 17.7234 12.4288 18 13.125 18C13.8212 18 14.4889 17.7234 14.9812 17.2312C15.4734 16.7389 15.75 16.0712 15.75 15.375V2.625C15.75 1.92881 15.4734 1.26113 14.9812 0.768845C14.4889 0.276562 13.8212 0 13.125 0ZM14.25 15.375C14.25 15.6734 14.1315 15.9595 13.9205 16.1705C13.7095 16.3815 13.4234 16.5 13.125 16.5C12.8266 16.5 12.5405 16.3815 12.3295 16.1705C12.1185 15.9595 12 15.6734 12 15.375V2.625C12 2.32663 12.1185 2.04048 12.3295 1.8295C12.5405 1.61853 12.8266 1.5 13.125 1.5C13.4234 1.5 13.7095 1.61853 13.9205 1.8295C14.1315 2.04048 14.25 2.32663 14.25 2.625V15.375Z" fill="#3E5196" />
      </g>
      <defs>
        <clipPath id="clip0_2044_255">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
