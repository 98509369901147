import React from 'react';

export default function CloseIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 22.1422L22.1421 8.00002" stroke="#FDFDFD" strokeWidth="3" strokeLinecap="round" />
      <path d="M22.1421 22.1422L7.99995 8.00002" stroke="#FDFDFD" strokeWidth="3" strokeLinecap="round" />
    </svg>
  );
}
