import { PointerSensor } from "@dnd-kit/core"
import React, { useEffect, useRef } from 'react';

export class MyPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown',
      handler: ({ nativeEvent: event }) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          isInteractiveElement(event.target)
        ) {
          return false;
        }

        return true;
      },
    },
  ];
}

function isInteractiveElement(element) {
  const interactiveElements = [
    'button',
    'input',
    'textarea',
    'select',
    'option'
  ];

  const classes = [
    'not-dnd',
    'popup'
  ]

  if (interactiveElements.includes(element.tagName.toLowerCase())) {
    return true;
  }

  if ([...element.classList].some(className => classes.includes(className)) || element.closest('.popup') || element.closest('.not-dnd')) {
    return true;
  }

  return false;
}
