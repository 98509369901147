import React from "react";
import { createPortal } from "react-dom";
import UsersPopup from "./UsersPopup";

export default function UsersPopupWrapper(props) {

  return (
    !props.insideParent ?
      createPortal((
        <UsersPopup
          taskId={props.taskId}
          project={props.project}
          users={props.users}
          handleClickClose={props.handleClickClose}
        />
      ), document.getElementById('modal_root'))
      :
      <UsersPopup
        taskId={props.taskId}
        project={props.project}
        users={props.users}
        handleClickClose={props.handleClickClose}
      />
  )
}
