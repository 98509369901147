import classNames from 'classnames';
import React, { useState } from 'react';
import { socketFileApi } from '../../../../../../../../../../API/api';
import { socket } from '../../../../../../../../../../API/socket';
import ArchiveIcon from '../../../../../../../../../../svgIcons/ArchiveIcon';
import CalendarIcon from '../../../../../../../../../../svgIcons/CalendarIcon';
import CheckTaskIcon from '../../../../../../../../../../svgIcons/CheckTaskIcon';
import CopyIcon from '../../../../../../../../../../svgIcons/CopyIcon';
import DeleteIcon from '../../../../../../../../../../svgIcons/DeleteIcon';
import EditSmallIcon from '../../../../../../../../../../svgIcons/EditSmallIcon';
import HashIcon from '../../../../../../../../../../svgIcons/HashIcon';
import LinkIcon from '../../../../../../../../../../svgIcons/LinkIcon';
import MoveIcon from '../../../../../../../../../../svgIcons/MoveIcon';
import SettingIcon from '../../../../../../../../../../svgIcons/SettingIcon';
import ShareIcon from '../../../../../../../../../../svgIcons/ShareIcon';
import SubTaskIcon from '../../../../../../../../../../svgIcons/SubTaskIcon';
import UploadIcon from '../../../../../../../../../../svgIcons/UploadIcon';
import UserIcon from '../../../../../../../../../../svgIcons/UserIcon';
import { copyLink } from '../../../../../../../../../../utils/copyLink';
import { FileBase64 } from '../../../../../../../../../../utils/fileToBase64';
import { generateKey } from '../../../../../../../../../../utils/generateKey';
import ConfirmWindow from '../../../../../../../../../common/ConfirmWindow/ConfirmWindow';
import SharePopupWrapper from '../../../../../../../../../common/SharePopup/SharePopupWrapper';
import BoardControls from '../../../../../../ProjectHeader/BoardControls/BoardControls';
import CheckListPopupWrapper from '../../TaskPopups/CheckListPopup/CheckListPopupWrapper';
import DeadlinePopupWrapper from '../../TaskPopups/DeadlinePopup/DeadlinePopupWrapper';
import TagPopupWrapper from '../../TaskPopups/TagPopup/TagPopupWrapper';
import TaskDuplicatePopupWrapper from '../../TaskPopups/TaskDuplicatePopup/TaskDuplicatePopupWrapper';
import TaskFilesPopupWrapper from '../../TaskPopups/TaskFilesPopup/TaskFilesPopupWrapper';
import TaskMovePopupWrapper from '../../TaskPopups/TaskMovePopup/TaskMovePopupWrapper';
import UsersPopupWrapper from '../../TaskPopups/UsersPopup/UsersPopupWrapper';
import styles from './tasksidebar.module.css';

export default function TaskSideBar(props) {

  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);
  const [isOpenTagPopup, setIsOpenTagPopup] = useState(false);
  const [isOpenUserPopup, setIsOpenUserPopup] = useState(false);
  const [isOpenDeadlinePopup, setIsOpenDeadlinePopup] = useState(false);
  const [isOpenCheckListPopup, setIsOpenCheckListPopup] = useState(false);
  const [isOpenMovePopup, setIsOpenMovePopup] = useState(false);
  const [isOpenDuplicatePopup, setIsDuplicatePopup] = useState(false);
  const [isOpenFilesPopup, setIsFilesPopup] = useState(false);
  const [isOpenTaskShare, setIsOpenTaskShare] = useState(false);
  const [actionKey, setActionKey] = useState('');

  const handleClickOpenConfirm = (key) => {
    setActionKey(key)
    setIsOpenConfirmPopup(true)
  }

  const handleClickClosePopup = () => {
    isOpenConfirmPopup && setIsOpenConfirmPopup(false);
    isOpenTagPopup && setIsOpenTagPopup(false);
    isOpenUserPopup && setIsOpenUserPopup(false);
    isOpenDeadlinePopup && setIsOpenDeadlinePopup(false);
    isOpenCheckListPopup && setIsOpenCheckListPopup(false);
    isOpenMovePopup && setIsOpenMovePopup(false);
    isOpenDuplicatePopup && setIsDuplicatePopup(false);
    isOpenFilesPopup && setIsFilesPopup(false);
    isOpenTaskShare && setIsOpenTaskShare(false);
    setActionKey('');
  }

  const handleDeleteTask = () => {
    socket.emit('deleteTask', props.task.id)
    console.log('Удалили задачу')
    handleClickClosePopup()
  }

  const handleArchiveTask = () => {
    socket.emit('ChangeTaskArchived', {
      'taskId': props.task.id,
      'archived': true
    })
    console.log('Заархивировали задачу');
    handleClickClosePopup()
  }

  const handleClickOpenTag = () => {
    setIsOpenTagPopup(true)
  }
  const handleClickOpenUser = () => {
    setIsOpenUserPopup(true);
  }
  const handleClickOpenDeadline = () => {
    setIsOpenDeadlinePopup(true);
  }
  const handleClickOpenCheckList = () => {
    setIsOpenCheckListPopup(true);
  }
  const handleClickOpenMove = (key) => {
    setActionKey(key);
    setIsOpenMovePopup(true);
  }
  const handleClickOpenDuplicate = () => {
    setIsDuplicatePopup(true);
  }
  const handleClickOpenFiles = () => {
    setIsFilesPopup(true);
  }
  const handleClickOpenShare = () => {
    setIsOpenTaskShare(true)
  }
  const handleSetBanner = (e) => {
    e.preventDefault();
    socketFileApi.setTaskBanner(props.task.id, e.target.files[0])
    .then(() => {
      socket.emit('SetTaskBanner', {
        "task_id": props.task.id,
        "file_uploladed": true
      })
    })
  }

  const controlsList = [
    // {
    //   icon: <UserIcon />,
    //   name: 'Участники',
    //   callBack: handleClickOpenUser,
    //   id: generateKey(),
    // },
    // {
    //   icon: <HashIcon />,
    //   name: 'Метка',
    //   callBack: handleClickOpenTag,
    //   id: generateKey(),
    // },
    {
      icon: <CalendarIcon />,
      name: 'Дата',
      callBack: handleClickOpenDeadline,
      id: generateKey(),
    },
    // {
    //   icon: <UploadIcon />,
    //   name: 'Вложения',
    //   callBack: handleClickOpenFiles,
    //   id: generateKey(),
    // },
    {
      icon: <CheckTaskIcon />,
      name: 'Чек-лист',
      callBack: handleClickOpenCheckList,
      id: generateKey(),
      hidden: !props.task.checklists?.items ? false : true,
    },
    {
      icon: <CopyIcon />,
      name: 'Копировать в...',
      callBack: handleClickOpenMove,
      id: generateKey(),
      key: 'copy'
    },
    {
      icon: <MoveIcon />,
      name: 'Переместить в...',
      callBack: handleClickOpenMove,
      id: generateKey(),
      key: 'move'
    },
    {
      icon: <CopyIcon />,
      name: 'Дублировать',
      callBack: handleClickOpenDuplicate,
      id: generateKey(),
    },
    // {
    //   icon: <ShareIcon />,
    //   name: 'Поделиться',
    //   callBack: handleClickOpenShare,
    //   id: generateKey(),
    // },
    {
      icon: <ArchiveIcon />,
      name: 'Архивировать',
      callBack: handleClickOpenConfirm,
      id: generateKey(),
      key: 'archive'
    },
    {
      icon: <DeleteIcon />,
      name: 'Удалить',
      callBack: handleClickOpenConfirm,
      id: generateKey(),
      key: 'delete'
    },
    {
      icon: <LinkIcon />,
      name: 'Копировать ссылку',
      callBack: () => copyLink(window.location.href, `задачу #task-${props.task.id}`),
      id: generateKey(),
    },
  ]

  return (
    <div
      onMouseDown={e => e.stopPropagation()}
      className={classNames('flex', 'iconBtnGroup', styles.taskSidebar)}
    >
      {/* <button className={classNames('btn', styles.sidebarBtn)}>
        Подписаться
      </button> */}
      {
        controlsList.filter(item => !item.hidden).map(item =>
          <button
            key={item.id}
            className={classNames('flex', 'iconBtn', 'nowrapString')}
            onClick={item.key
              ?
              () => {
                item.callBack(item.key);
              }
              :
              () => {
                item.callBack();
              }}
            style={item.console && { background: 'red' }}
            type="button"
          >
            {item.icon}
            {item.name}
          </button>
        )
      }
      {
        !props.banner &&
        <div className={classNames('fileInputWrapper', 'flex', styles.file)}>
          <input
            type='file'
            accept=".jpg, .jpeg, .png"
            className={'fileInput'}
            name='banner'
            id='bannerSideBar'
            onChange={(e) => {
              handleSetBanner(e)
            }}
          >
          </input>
          <label
            className={classNames('flex', 'fileLabel', styles.fileLabel)}
            htmlFor="bannerSideBar"
          >
            <EditSmallIcon />
            Загрузить баннер
          </label>
        </div>
      }
      {
        isOpenConfirmPopup &&
        <ConfirmWindow
          insideParent={true}
          title={actionKey === 'delete' ? 'Удалить задачу?' : 'Архивировать задачу?'}
          onDelete={actionKey === 'delete' ? handleDeleteTask : handleArchiveTask}
          closeDelete={handleClickClosePopup}
          btnText={actionKey !== 'delete' ? 'Подтвердить' : false}
        />
      }
      {
        isOpenTagPopup &&
        <TagPopupWrapper
          taskId={props.task.id}
          insideParent={true}
          handleClickClose={handleClickClosePopup}
          boardId={props.boardId}
        />
      }
      {
        isOpenUserPopup &&
        <UsersPopupWrapper
          project={props.project}
          taskId={props.task.id}
          users={props.task.performers}
          insideParent={true}
          handleClickClose={handleClickClosePopup}
        />
      }
      {
        isOpenDeadlinePopup &&
        <DeadlinePopupWrapper
          taskId={props.task.id}
          deadline={props.task.deadline}
          insideParent={true}
          handleClickClose={handleClickClosePopup}
        />
      }
      {
        isOpenCheckListPopup &&
        <CheckListPopupWrapper
          insideParent={true}
          handleClickClose={handleClickClosePopup}
          taskId={props.task.id}
          boardId={props.boardId}
        />
      }
      {
        isOpenMovePopup &&
        <TaskMovePopupWrapper
          insideParent={true}
          keyAct={actionKey === 'move' ? 'move' : 'copy'}
          handleClickClose={handleClickClosePopup}
          taskComm={props.task.comments}
          taskAddit={props.task.attachments}
          taskCheck={props.task.checklists}
          taskName={props.task.name}
          taskId={props.task.id}
          subtasks={props.task.subtasks}
        />
      }
      {
        isOpenDuplicatePopup &&
        <TaskDuplicatePopupWrapper
          taskName={props.task.name}
          taskComm={props.task.comments}
          taskAddit={props.task.attachments}
          taskCheck={props.task.checklists}
          insideParent={true}
          handleClickClose={handleClickClosePopup}
          taskId={props.task.id}
          colId={props.colId}
          subtasks={props.task.subtasks}
        />
      }
      {
        isOpenFilesPopup &&
        <TaskFilesPopupWrapper
          forTasks={true}
          insideParent={true}
          handleClickClose={handleClickClosePopup}
        />
      }
      {
        isOpenTaskShare && <SharePopupWrapper
          insideParent={true}
          handleClickClose={handleClickClosePopup}
          popupHeader={`Поделиться задачей`}
        />
      }
    </div>
  );
}
