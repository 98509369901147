import React from "react";
import { createPortal } from "react-dom";
import AddFilesPopup from "../../AddFilesPopup/AddFilesPopup";

export default function CommentAddFileWrapper(props) {

  return (
    !props.insideParent ?
      createPortal((
        <AddFilesPopup
          files={props.files}
          handleClickClose={props.handleClickClose}
          setFiles={props.setFiles}
          type={props.type}
        />
      ), document.getElementById('modal_root'))
      :
      <AddFilesPopup
        files={props.files}
        handleClickClose={props.handleClickClose}
        setFiles={props.setFiles}
        type={props.type}
      />
  )
}
