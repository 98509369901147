import { tariffAPI } from "../../API/api";

const SET_TARIFF = 'SET_TARIFF';

let initialState = {
  tariff: {},
  isFetching: false
};

export const tariffReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TARIFF:
      return {
        ...state,
        tariff: action.tariff,
      }
    default: return { ...state };
  }
}

const setTariff = (tariff) => ({ type: SET_TARIFF, tariff });

export const getTariff = ({ organization_id: organization_id }) => async (dispatch) => {
  await tariffAPI.getTariff({ organization_id: organization_id })
    .then(response => dispatch(setTariff(response.data)))
    .catch(err => console.log(err))
}
