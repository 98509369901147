import React from 'react';
import { socketFileApi } from '../../../../../../../../../../API/api';
import { socket } from '../../../../../../../../../../API/socket';
import { FileBase64 } from '../../../../../../../../../../utils/fileToBase64';
import Comments from '../../../../../../../../../common/Comments/Comments';

export default function TaskCommentContainer(props) {

  const submitFunc = (data, files, text) => {
    const arrFiles = [...files.map(item => {
      return {
        "name": item.name,
        "file": item,
      };
    })]

    const array = text.split('<p><br></p>');
    const isNotAllow = array.length === array.filter(item => item === '').length

    if ((!arrFiles.length || !arrFiles?.length === 0) && isNotAllow) {
      return
    } else if ((!arrFiles.length || !arrFiles?.length === 0) && !isNotAllow) {
      socket.emit('AddComment', {
        "taskId": data.task,
        "authorId": data.author,
        "text": text,
        "fileIds": []
      })
    } else {
      socketFileApi.setTaskCommentFiles(arrFiles)
        .then(res => {
          socket.emit('AddComment', {
            "taskId": data.task,
            "authorId": data.author,
            "text": text || '<p></p>',
            "fileIds": res.data
          })
        })
    }
  }


  const handleEdit = (id, newFiles, text, currentFilesList) => {
    const arrNewFiles = [...newFiles.map(item => {
      return {
        "file": item,
        "name": item.name, 
      };
    })]

    const arrCurrentFiles = [...currentFilesList.map(item => item.id)];
    const array = text.split('<p><br></p>');
    const isNotAllow = array.length === array.filter(item => item === '').length;

    if ((!arrCurrentFiles.length || !arrCurrentFiles?.length === 0) && (!arrNewFiles.length || !arrNewFiles?.length === 0) && isNotAllow) {
      return
    } else {
      if (arrNewFiles.length > 0) {
        socketFileApi.setTaskCommentFiles(arrNewFiles)
          .then(res => {
            socket.emit('EditComment', {
              "commentId": id,
              "text": text || '<p></p>',
              "fileIds": res.data.concat(arrCurrentFiles),
            })
          })
      } else {
        socket.emit('EditComment', {
          "commentId": id,
          "text": text || '<p></p>',
          "fileIds": arrCurrentFiles,
        })
      }
    }
  }

  const handleDelete = (id) => {
    socket.emit('DeleteComment', { "commentId": id })
  }

  return (
    <Comments
      readOnly={props.readOnly}
      targetField={'task'}
      comments={props.comments}
      targetId={props.targetId}
      submitFunc={submitFunc}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      insideParent={true}
      inTask={true}
      forComment={true}
    />
  );
}
