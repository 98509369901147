import classNames from 'classnames';
import React from 'react';
import { useState } from 'react';
import CommentIcon from '../../../../../../svgIcons/CommentIcon';
import PauseIcon from '../../../../../../svgIcons/PauseIcon';
import PlayIcon from '../../../../../../svgIcons/PlayIcon';
import styles from './taskitem.module.css';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { createTimerItem, deleteTimerItem, stopTimerItem } from '../../../../../../redux/Tracker/timerReducer';
import { editTimer } from '../../../../../../utils/editTimer';
import { useEffect } from 'react';
import DeleteIcon from '../../../../../../svgIcons/DeleteIcon';

export default function TaskItem(props) {

  const dispatch = useDispatch();

  const [timerStart, setTimerStart] = useState(props.timer?.intervals[props.timer?.intervals?.length - 1]?.end_time === null);
  const [time, setTime] = useState(props.timer?.total_duration);
  const [showDel, setShowDel] = useState(false);

  useEffect(() => {
    setTimerStart(props.timer?.intervals[props.timer?.intervals?.length - 1]?.end_time === null);

    return () => { }
  }, [props.timer?.intervals])

  useEffect(() => {
    setTime(props.timer?.total_duration)

    return () => { }
  }, [props.timer?.total_duration])

  const createTimer = () => {
    dispatch(createTimerItem(props.item.id))
      .then(() => setTimerStart(true))
  }

  const stopTimer = () => {
    dispatch(stopTimerItem(props.item.id))
      .then(() => setTimerStart(false))
  }

  const deleteTimer = () => {
    dispatch(deleteTimerItem(props.timer?.id))
      .then(() => setTimerStart(false))
  }

  useEffect(() => {
    let timerItem;

    if (timerStart) {
      timerItem = setTimeout(() => setTime(prev => prev + 1), 1000)
    } else {
      clearInterval(timerItem)
    }

    return () => { clearInterval(timerItem) }
  }, [time, timerStart]);

  return (
    <NavLink
      to={`/projects/${props.item.task_column?.task_board?.project?.id}/board/${props.item.task_column?.task_board?.id}#task-${props.item.id}`}
      className={classNames(styles.item)}
    // target="_blank"
    >
      <p className={styles.name}>
        {props.item.name}
      </p>
      <div className={styles.path}>
        {props.item.task_column?.task_board?.project?.name}&nbsp;/&nbsp;
        {props.item.task_column?.task_board?.name}&nbsp;/&nbsp;
        {props.item.task_column?.name}
      </div>
      <div className={classNames('flex', styles.btns)}>
        <div className={classNames('flex', 'iconBtnGroup')}>
          {
            props.item.comments?.length > 0 &&
            <div className={classNames('flex', 'iconBtn', styles.comments)}>
              {props.item.comments?.length}
              <CommentIcon />
            </div>
          }
          {
            timerStart ?
              <button
                onClick={(e) => {
                  e.preventDefault()
                  stopTimer()
                }}
                className={classNames('flex', 'iconBtn')}
              >
                <PauseIcon />
              </button>
              :
              <button
                onClick={(e) => {
                  e.preventDefault()
                  createTimer()
                }}
                className={classNames('flex', 'iconBtn')}
              >
                <PlayIcon />
              </button>
          }
        </div>
        {
          props.timer &&
          <div
            onClick={(e) => e.preventDefault()}
            onMouseEnter={() => setShowDel(true)}
            onMouseLeave={() => setShowDel(false)}
            className={classNames(styles.timer, timerStart && styles.active)}>
            {
              editTimer(time)
            }
            {
              showDel &&
              <button
                onClick={() => deleteTimer()}
                className={classNames(styles.delBtn, 'iconBtn', 'flex')}
              >
                <DeleteIcon />
              </button>
            }

          </div>
        }

      </div>
    </NavLink >
  );
}
