import React from 'react';

export default function CheckTaskIcon(props) {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2857_1120)">
        <path d="M12 16H4C2.93952 15.9987 1.92285 15.5769 1.17298 14.827C0.423106 14.0772 0.00127029 13.0605 0 12L0 4C0.00127029 2.93952 0.423106 1.92285 1.17298 1.17298C1.92285 0.423106 2.93952 0.00127029 4 0L12 0C13.0605 0.00127029 14.0772 0.423106 14.827 1.17298C15.5769 1.92285 15.9987 2.93952 16 4V12C15.9987 13.0605 15.5769 14.0772 14.827 14.827C14.0772 15.5769 13.0605 15.9987 12 16ZM4 1.6C3.36348 1.6 2.75303 1.85286 2.30294 2.30294C1.85286 2.75303 1.6 3.36348 1.6 4V12C1.6 12.6365 1.85286 13.247 2.30294 13.6971C2.75303 14.1471 3.36348 14.4 4 14.4H12C12.6365 14.4 13.247 14.1471 13.6971 13.6971C14.1471 13.247 14.4 12.6365 14.4 12V4C14.4 3.36348 14.1471 2.75303 13.6971 2.30294C13.247 1.85286 12.6365 1.6 12 1.6H4Z" fill="#3E5196" />
        <path d="M11.3847 6.60107C11.6094 6.31479 11.6129 5.84654 11.3927 5.55508C11.1708 5.26363 10.8119 5.25845 10.5883 5.54547L8.07101 8.75887C7.62729 9.32403 6.93041 9.32699 6.48896 8.77219L5.41207 7.39554C5.1885 7.10853 4.82726 7.11222 4.60768 7.40442C4.38752 7.69587 4.39036 8.16413 4.6145 8.4504L5.6948 9.83149C6.13853 10.3885 6.70627 10.6666 7.27401 10.6666C7.84801 10.6666 8.42144 10.3833 8.86687 9.81521L11.3847 6.60107Z" fill="#3E5196" />
      </g>
      <defs>
        <clipPath id="clip0_2857_1120">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
