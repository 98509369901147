import React from 'react';

export default function LockRoundIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29ZM15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#3E5196" />
      <path d="M19.0832 12.414V11.5833C19.0832 10.5004 18.653 9.46175 17.8872 8.69598C17.1214 7.93021 16.0828 7.5 14.9998 7.5C13.9169 7.5 12.8783 7.93021 12.1125 8.69598C11.3467 9.46175 10.9165 10.5004 10.9165 11.5833V12.414C10.397 12.6407 9.95476 13.014 9.64396 13.488C9.33317 13.9621 9.16725 14.5165 9.1665 15.0833V18.5833C9.16743 19.3566 9.47502 20.0979 10.0218 20.6447C10.5686 21.1915 11.3099 21.4991 12.0832 21.5H17.9165C18.6898 21.4991 19.4311 21.1915 19.9779 20.6447C20.5247 20.0979 20.8322 19.3566 20.8332 18.5833V15.0833C20.8324 14.5165 20.6665 13.9621 20.3557 13.488C20.0449 13.014 19.6027 12.6407 19.0832 12.414ZM12.0832 11.5833C12.0832 10.8098 12.3905 10.0679 12.9374 9.52094C13.4844 8.97396 14.2263 8.66667 14.9998 8.66667C15.7734 8.66667 16.5153 8.97396 17.0622 9.52094C17.6092 10.0679 17.9165 10.8098 17.9165 11.5833V12.1667H12.0832V11.5833ZM19.6665 18.5833C19.6665 19.0475 19.4821 19.4926 19.1539 19.8208C18.8258 20.149 18.3806 20.3333 17.9165 20.3333H12.0832C11.619 20.3333 11.1739 20.149 10.8457 19.8208C10.5175 19.4926 10.3332 19.0475 10.3332 18.5833V15.0833C10.3332 14.6192 10.5175 14.1741 10.8457 13.8459C11.1739 13.5177 11.619 13.3333 12.0832 13.3333H17.9165C18.3806 13.3333 18.8258 13.5177 19.1539 13.8459C19.4821 14.1741 19.6665 14.6192 19.6665 15.0833V18.5833Z" fill="#3E5196" />
      <path d="M14.9998 15.6666C14.8451 15.6666 14.6968 15.7281 14.5874 15.8375C14.478 15.9469 14.4165 16.0952 14.4165 16.25V17.4166C14.4165 17.5713 14.478 17.7197 14.5874 17.8291C14.6968 17.9385 14.8451 18 14.9998 18C15.1545 18 15.3029 17.9385 15.4123 17.8291C15.5217 17.7197 15.5832 17.5713 15.5832 17.4166V16.25C15.5832 16.0952 15.5217 15.9469 15.4123 15.8375C15.3029 15.7281 15.1545 15.6666 14.9998 15.6666Z" fill="#3E5196" />
    </svg>
  );
}
