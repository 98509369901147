import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMe } from '../../../../redux/authSelectors';
import { getProjects } from '../../../../redux/projectListReducer';
import { selectProjects } from '../../../../redux/projectListSelector';
import { generateKey } from '../../../../utils/generateKey';
import Navigation from './Navigation';


export default function NavigationContainer(props) {

  const dispatch = useDispatch();
  const projects = useSelector(selectProjects);
  const me = useSelector(selectMe)

  useEffect(() => {
    (me && me?.active_organization) &&
      dispatch(getProjects({
        organization_ids: me?.active_organization?.id
      }))

  }, [dispatch, me])

  return (
    <Navigation
      isFull={props.isFull}
      projects={projects}
      handleOpen={props.handleOpen}
      menuWidth={props.menuWidth}
      width={props.width}
      me={me}
    />
  );
}
