import { combineReducers, legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'
import { authReducer } from './authReducer';
import { columnsReducer } from './BoardPage/сolumnsReducer';
import { boardsReducer } from './boardsListReducer';
import { chatReducer } from './Chat/chatReducer';
import { departmentsReducer } from './CompanyPage/companyDepartmentsReducer';
import { companyReducer } from './CompanyPage/companyInfoReducer';
import { newsReducer } from './News/newsReducer';
import { userCalendarReducer } from './Profile/userCalendarReducer';
import { userCommentsReducer } from './Profile/userCommentsReducer';
import { userProfile } from './Profile/userProfile';
import { projectsReducer } from './projectListReducer';
import { projectCommentsReducer } from './ProjectPage/projectCommentsReducer';
import { projectFinanceReducer } from './ProjectPage/projectFinanceReducer';
import { projectItemReducer } from './ProjectPage/projectItemReducer';
import { projectPlanReducer } from './ProjectPage/projectPlanReducer';
import { projectStatusesReducer } from './projectStatusesReducer';
import { tariffReducer } from './Tariff/tariffReducer';
import { myTasksReducer } from './Tracker/myTaskReducer';
import { myTimersReducer } from './Tracker/timerReducer';
import { usersPositionsReducer } from './usersPositionsReducer';
import { usersReducer } from './usersReducer';

let reducers = combineReducers({
  auth: authReducer,
  projects: projectsReducer,
  projectItem: projectItemReducer,
  projectComments: projectCommentsReducer,
  projectFinance: projectFinanceReducer,
  projectPlan: projectPlanReducer,
  projectStatuses: projectStatusesReducer,
  users: usersReducer,
  usersPositions: usersPositionsReducer,
  boards: boardsReducer,
  columns: columnsReducer,
  companyInfo: companyReducer,
  departments: departmentsReducer,
  news: newsReducer,
  chat: chatReducer,
  userCalendar: userCalendarReducer,
  userProfile: userProfile,
  userComments: userCommentsReducer,
  myTasks: myTasksReducer,
  myTimers: myTimersReducer,
  tariff: tariffReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(reducers, composeEnhancers(applyMiddleware(thunk)));
