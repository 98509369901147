import classNames from 'classnames';
import React from 'react';
import TimerIcon from '../../../../../svgIcons/TimerIcon';
import Time from '../Time/Time';
import styles from './collapsetimer.module.css';

export default function CollapseTimer(props) {

  return (
    <div className={classNames('flex', styles.collapse)}>
      <button
        onClick={() => props.showFull(true)}
        className={classNames('flex', 'iconBtn')}
      >
        <TimerIcon />
      </button>
      <Time
        time={props.time}
      />
    </div>
  );
}
