import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { editNameWithoutDots } from '../../../../../../../../../../utils/nameEditor';
import styles from './taskuser.module.css';

export default function TaskUser(props) {

  return (
    <NavLink
      to={`/user/${props.user?.user?.id}`}
      style={{ backgroundColor: `${props.user?.user?.avatar_color}` }}
      className={classNames('imageBox','flex', styles.user)}
      onClick={e => e.stopPropagation()}
    >
      {
        props.user?.user?.avatar ?
          <img src={props.user?.user?.avatar} alt="ava" />
          :
          <span className={styles.initial}>
            {editNameWithoutDots(props.user?.user?.first_name)}{editNameWithoutDots(props.user?.user?.last_name)}
          </span>
      }
    </NavLink>
  );
}
