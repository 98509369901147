import React, { useEffect } from 'react';
import { setupInterceptor } from '../../API/api';
import Content from './Content/Content';
import styles from './sitepage.module.css';
import { store } from '../../redux/reduxStore';
import Sidebar from './Sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { getMe } from '../../redux/authReducer';
import { useState } from 'react';
import { getProjects } from '../../redux/projectListReducer';
import { selectMe } from '../../redux/authSelectors';
import { useLocation, useNavigate } from 'react-router-dom';

export default function SitePage(props) {
  setupInterceptor(store)

  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth)
    })
    return () => { }
  }, [])

  const dispatch = useDispatch()

  const url = useLocation();

  useEffect(() => {
    !url.pathname.includes('accept-invitation') && dispatch(getMe())
  }, [dispatch, url])

  const [menuWidth, setMenuWidth] = useState('');

  const me = useSelector(selectMe);
  const navigate = useNavigate();

  useEffect(() => {
    if (me?.user && !me?.user?.first_name?.trim() && !me?.user?.last_name?.trim()) {
      navigate(`/welcome`)
    }
  }, [me])

  return (
    <div className={styles.sitePage}>
      <div className={styles.sidebar}>
        <Sidebar
          width={width}
          setMenuWidth={setMenuWidth}
          menuWidth={menuWidth}
        />
      </div>
      <div
        style={width > 576 ? { paddingLeft: `${menuWidth}px` } : { paddingLeft: `30px` }}
        className={styles.content}
      >
        <Content
          width={width}
        />
      </div>
    </div>
  );
}
