import { tasksWithAPI } from "../../API/api";

const SET_MY_TASKS = 'SET_MY_TASKS';

let initialState = {
  tasks: [],
  isFetching: false
};

export const myTasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_MY_TASKS:
      return {
        ...state,
        tasks: action.tasks,
      }

    default: return { ...state };
  }
}

const setTasks = (tasks) => ({ type: SET_MY_TASKS, tasks });

export const getMyTasks = (id) => async (dispatch) => {
  await tasksWithAPI.getTasks(id)
    .then(response => dispatch(setTasks(response.data)))
    .catch(err => console.log(err))
}
