import classNames from 'classnames';
import React from 'react';
import styles from './payheader.module.css';

export default function PayHeader(props) {

  return (
    <div className={classNames('flex', 'pageHeader')}>

    </div>
  );
}
