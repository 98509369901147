import React from "react";
import { createPortal } from "react-dom";
import DeadlinePopup from "./DeadlinePopup";

export default function DeadlinePopupWrapper(props) {

  return (
    !props.insideParent ?
      createPortal((
        <DeadlinePopup
          taskId={props.taskId}
          handleClickClose={props.handleClickClose}
          deadline={props.deadline}
        />
      ), document.getElementById('modal_root'))
      :
      <DeadlinePopup
        taskId={props.taskId}
        handleClickClose={props.handleClickClose}
        deadline={props.deadline}
      />
  )
}
