import { projectsListAPI } from "../API/api";
import { mapObj } from "../utils/objectHelpers";

const SET_PROJECTS = 'SET_PROJECTS';
const SET_ARCHIVED_PROJECTS = 'SET_ARCHIVED_PROJECTS';
const CREATE_PROJECT = 'CREATE_PROJECT';
const DELETE_PROJECT = 'DELETE_PROJECT';
const EDIT_ITEM = 'EDIT_ITEM';
const EDIT_ARCHIVED_TRUE = 'EDIT_ARCHIVED_TRUE';
const EDIT_ARCHIVED_FALSE = 'EDIT_ARCHIVED_FALSE';
const DUPLICATE_PROJECT = 'DUPLICATE_PROJECT';

let initialState = {
  projects: [],
  archivedProjects: [],
  isFetching: false
};

export const projectsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECTS:
      return {
        ...state,
        projects: [...action.projects],
      }
    case SET_ARCHIVED_PROJECTS:
      return {
        ...state,
        archivedProjects: [...action.archivedProjects],
      }
    case CREATE_PROJECT:
      return {
        ...state,
        projects: [action.newProject, ...state.projects],
      }
    case DUPLICATE_PROJECT:
      return {
        ...state,
        projects: [action.duplicateProject, ...state.projects],
      }
    case EDIT_ARCHIVED_TRUE:
      return {
        ...state,
        projects: state.projects.filter((el) => el.id !== action.projId),
        archivedProjects: [action.newObj, ...state.archivedProjects],
      }
    case EDIT_ARCHIVED_FALSE:
      return {
        ...state,
        archivedProjects: state.archivedProjects.filter((el) => el.id !== action.projId),
        projects: [action.newObj, ...state.projects],
      }
    case DELETE_PROJECT:
      return {
        ...state,
        projects: state.projects.filter((el) => el.id !== action.projectId),
        archivedProjects: state.archivedProjects.filter((el) => el.id !== action.projectId),
      }
    case EDIT_ITEM:
      return {
        ...state,
        projects: mapObj(state.projects, action.projId, 'id', action.newObj),
      }
    default: return { ...state };
  }
}

const setProjects = (projects) => ({ type: SET_PROJECTS, projects });
const setArchivedProjects = (archivedProjects) => ({ type: SET_ARCHIVED_PROJECTS, archivedProjects });
const setCreateProject = (newProject) => ({ type: CREATE_PROJECT, newProject });
const setDuplicateProject = (duplicateProject) => ({ type: DUPLICATE_PROJECT, duplicateProject });
const setDeleteProject = (projectId) => ({ type: DELETE_PROJECT, projectId });
const setEditItem = (projId, newObj) => ({ type: EDIT_ITEM, projId, newObj });

const setArchivedTrue = (projId, newObj) => ({ type: EDIT_ARCHIVED_TRUE, projId, newObj })
const setArchivedFalse = (projId, newObj) => ({ type: EDIT_ARCHIVED_FALSE, projId, newObj })

export const getProjects = ({
  organization_ids: organization_ids
}) => async (dispatch) => {
  await projectsListAPI.getProjectList({
    organization_ids: organization_ids
  })
    .then(response => dispatch(setProjects(response.data)))
    .catch(err => console.log(err))
}

export const getArchivedProjects = ({
  organization_ids: organization_ids
}) => async (dispatch) => {
  await projectsListAPI.getArchivedProjectList({
    organization_ids: organization_ids
  })
    .then(response => dispatch(setArchivedProjects(response.data)))
    .catch(err => console.log(err))
}

export const createProjects = (
  {
    companyId: companyId,
    name: name,
    manager: manager,
    client: client,
    workers: workers,
    price: price,
    description: description,
    start_date: start_date
  }
) => async (dispatch) => {
  await projectsListAPI.createProject(
    {
      companyId: companyId,
      name: name,
      manager: manager,
      client: client,
      workers: workers,
      price: price,
      description: description,
      start_date: start_date
    }
  )
    .then(response => dispatch(setCreateProject(response.data)))
    .catch(err => console.log(err))
}

export const duplicateProject = (
  {
    id: id
  }
) => async (dispatch) => {
  await projectsListAPI.duplicateProject(
    {
      id: id
    }
  )
    .then(response => dispatch(setDuplicateProject(response.data)))
    .catch(err => console.log(err))
}

export const deleteProject = (id) => async (dispatch) => {
  await projectsListAPI.deleteProject(id)
    .then(() => dispatch(setDeleteProject(id)))
}

export const editProjectItem = (
  {
    id: id,
    newObj: newObj
  }
) => (dispatch) => {
  dispatch(setEditItem(id, newObj))
}


export const editProjectArchivedInList = (
  {
    id: id,
    newObj: newObj,
    archived: archived
  }
) => (dispatch) => {
  archived ?
    dispatch(setArchivedTrue(id, newObj))
    :
    dispatch(setArchivedFalse(id, newObj))
}
