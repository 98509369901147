import classNames from 'classnames';
import React, { useState } from 'react';
import styles from './addcolumn.module.css';
import { socket } from '../../../../../../API/socket';

export default function AddColumn(props) {

  const [value, setValue] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    socket.emit('addColumn',
      {
        boardId: props.boardId,
        name: value
      }
    )

    setValue('')
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames('flex', 'contentBlock', styles.addColumn)}
    >
      <input
        onChange={(e) => setValue(e.target.value)}
        value={value}
        className={classNames('input')}
        placeholder='Название'
        type="text"
      />
      <button
        disabled={!value}
        type='submit'
        className={classNames('btn', styles.addColumnBtn)}
      >
        Добавить
      </button>
    </form>
  );
}
