import React, { useState } from 'react';
import styles from './commentaccess.module.css';
import classNames from 'classnames';
import StatusList from '../../StatusList/StatusList';
import LockIcon from '../../../../svgIcons/LockIcon';

export default function CommentAccess(props) {

  const [isOpenPopup, setIsOpenPopup] = useState(false);

  const statusList = [
    {
      name: 'Личное',
      id: 'personal'
    },
    {
      name: 'Для всех',
      id: 'for_all'
    },
    {
      name: 'Скрыть от заказчика',
      id: 'hidden'
    },
  ];

  return (
    <div className={styles.accessWrapper}>
      <button
        onClick={() => setIsOpenPopup(true)}
        type="button"
        className={classNames('flex', 'iconBtn', styles.access)}
      >
        <LockIcon />
      </button>

      {
        isOpenPopup &&
        <StatusList
          commentAccess={true}
          statusList={statusList}
          handleClickClose={setIsOpenPopup}
          statusClick={props.setAccessValue}
          isCheck={props.isCheck}
        />
      }
    </div>
  );
}
