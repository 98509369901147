import React from 'react';
import { useEffect } from 'react';
import { lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { getCategories, getNewsList } from '../../../../redux/News/newsReducer';
import { selectCategories, selectNewsList } from '../../../../redux/News/newsSelector';
import { generateKey } from '../../../../utils/generateKey';
import Preloader from '../../../common/Preloader/Preloader';

const News = lazy(() => import('./News'));

export default function NewsContainer(props) {

  let { categoryId } = useParams();

  const news = useSelector(selectNewsList);
  const categories = useSelector(selectCategories);
  const dispatch = useDispatch()

  const categoriesAll = [
    {
      id: 'AllNewsID',
      name: 'Все',
      slug: 'vse'
    },
    ...categories
  ]

  const pageSize = 6;

  const link = useLocation();

  useEffect(() => {
    dispatch(getCategories())
  }, [dispatch])

  useEffect(() => {
    const hash = link.hash;
    dispatch(getNewsList({ category: categoryId, page: hash ? hash.slice(hash.indexOf('-') + 1, hash.length) : 1, pageSize: pageSize }))
  }, [dispatch, categoryId, link])

  return (
    <Suspense fallback={<Preloader />}>
      <News
        categories={categoriesAll}
        news={news}
        pageSize={pageSize}
        currentCategory={categoryId}
      />
    </Suspense>
  );
}
