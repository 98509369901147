import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './invite.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { getInvite } from '../../../../redux/authReducer';

export default function Invite(props) {

  const [errorInvite, setErrorInvite] = useState(false);

  let { inviteId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (inviteId) {
      dispatch(getInvite(inviteId))
        .then(() => {
          navigate('/')
        })
        .catch((err) => setErrorInvite(true))
    }
  }, [inviteId])

  return (
    <div className={styles.invite}>
      {/* <h1>Добро пожаловать в BOOM-TRACK!</h1> */}
      <h2>Секундочку... Мы идентифицируем Вас :)</h2>
      {
        errorInvite &&
        <p className={styles.error}>Произошла ошибка</p>
      }
    </div>
  );
}
