import classNames from 'classnames';
import React from 'react';
import styles from './taskbanner.module.css';

export default function TaskBanner(props) {

  return (
    <div className={classNames('flex', 'imageBox', styles.taskBanner)}>
      <img src={props.img} alt="banner" />
    </div>
  );
}
