import classNames from 'classnames';
import React from 'react';
import { useState } from 'react';
import AddIcon from '../../../../../../../../../svgIcons/AddIcon';
import TagsListPopup from './TagsListPopup/TagsListPopup';
import styles from './taskheader.module.css';

export default function TaskHeader(props) {

  const [isOpenTags, setIsOpenTags] = useState(false)

  return (
    <div className={classNames('flex', styles.taskHeader)}>
      <button
        onClick={(e) => {
          e.stopPropagation()
          setIsOpenTags(true)
        }}
        className={classNames('flex', 'iconBtn', styles.addTag)}
      >
        <AddIcon />
        {
          isOpenTags &&
          <TagsListPopup
            handleClickClose={setIsOpenTags}
            taskId={props.taskId}
          />
        }
      </button>
      <div className={classNames('flex', 'tagsWrapper')}>
        {props.tags?.map((item, index) =>
          <span
            key={index}
            style={{ 'backgroundColor': `${item.color}` }}
            className={classNames('tag', Boolean(item.color) && 'tagWhite')}
          >
            #{item.name}
          </span>
        )}
      </div>
    </div>
  );
}
