import React from 'react';

export default function DotsIcon(props) {
  return (
    <svg width="3" height="12" viewBox="0 0 3 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.5 3C2.328 3 3 2.26431 3 1.5C3 0.735692 2.328 0 1.5 0C0.672 0 0 0.735692 0 1.5C0 2.26431 0.672 3 1.5 3Z" fill="#3E5196" />
      <path d="M1.5 7.38462C2.328 7.38462 3 6.64892 3 5.88461C3 5.12031 2.328 4.38462 1.5 4.38462C0.672 4.38462 0 5.12031 0 5.88461C0 6.64892 0.672 7.38462 1.5 7.38462Z" fill="#3E5196" />
      <path d="M1.5 12C2.328 12 3 11.2643 3 10.5C3 9.73569 2.328 9 1.5 9C0.672 9 0 9.73569 0 10.5C0 11.2643 0.672 12 1.5 12Z" fill="#3E5196" />
    </svg>
  );
}
