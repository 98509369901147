import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { socket } from "../../../../../../../../../../API/socket";
import CheckedIcon from "../../../../../../../../../../svgIcons/CheckedIcon";
import CheckRoundIcon from "../../../../../../../../../../svgIcons/CheckRoundIcon";
import EditSmallIcon from "../../../../../../../../../../svgIcons/EditSmallIcon";
import { parseHTML } from "../../../../../../../../../../utils/parseHtml";
import Editor from "../../../../../../../../../common/Editor/Editor";
import styles from './taskdescrfield.module.css';

export default function TaskDescrField(props) {

  const [editDescr, setEditDescr] = useState((!props.descr || props.descr === '<p></p>' || props.descr === '<p><br></p>') || false);

  // For editor (code from lib)
  const [editorHtml, setEditorHtml] = useState(props.descr);
  const [quillRef, setQuillRef] = useState(null);
  const reactQuillRef = useRef();
  const refWrapper = useRef(null);

  const attachQuillRefs = () => {
    if (!reactQuillRef.current || typeof reactQuillRef.current.getEditor !== 'function') return;
    if (quillRef) return;

    const editor = reactQuillRef.current.getEditor();
    if (editor !== null) setQuillRef(editor);
  };

  const handleChange = (html) => {
    setEditorHtml(html);
  };

  useEffect(() => {
    attachQuillRefs();
  }, []);

  const showEditor = () => {
    refWrapper.current.classList.toggle('active');
  }

  useEffect(() => {
    const toolbar = refWrapper.current?.querySelector('.ql-toolbar')
    console.log(toolbar)

    toolbar?.addEventListener('mousedown', (e) => {
      e.preventDefault()
    })

    return () => toolbar?.removeEventListener('click', e => e.preventDefault())
  }, [refWrapper.current])

  const onSubmit = (() => {
    const array = editorHtml?.split('<p><br></p>');
    const isNotAllow = array ? array?.length === array?.filter(item => item === '').length : true;

    socket.emit('ChangeTaskDescription', {
      'taskId': props.taskId,
      'description': editorHtml || '<p></p>'
    });
    !isNotAllow && setEditDescr(false)
  })

  // KeyDown
  const handleKeyDown = (e) => {
    if (e.code.includes('Enter') && e.shiftKey) {
      return
    }
    if (e.code.includes('Enter') && !e.shiftKey) {
      e.preventDefault()
      onSubmit()
    }
  }

  return (
    (!props.descr || props.descr !== '<p></p>' || props.descr !== '<p><br></p>') && !props.readOnly &&
    <>
      {
        !editDescr ?
          <div>
            <span
              className={classNames(styles.taskDescr, 'ql-editor')}
              onClick={() => {
                !props.readOnly && setEditDescr(true)
              }}
            >
              {parseHTML(props.descr)}
            </span>
          </div>
          :
          <div className={classNames('flex', styles.edit)}>
            <div
              className={classNames('areaWrapper', 'addQuillWrapper', 'editQuillWrapper', 'quillWrapper', styles.descrWrap)}
              ref={refWrapper}
            >
              <Editor
                onSubmit={onSubmit}
                handleChange={handleChange}
                handleKeyDown={handleKeyDown}
                editorHtml={editorHtml}
                reactQuillRef={reactQuillRef}
                placeholder={'Введите текст описания'}
                showEditor={showEditor}
                inTask={true}
              />
            </div>
            <button
              type='button'
              onClick={(e) => {
                e.preventDefault()
                onSubmit()
              }}
              className={classNames('flex', 'iconBtn', styles.submitBtn)}
            >
              <CheckRoundIcon />
            </button>
          </div>

      }
    </>

  )
}
