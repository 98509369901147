import React from "react";
import { createPortal } from "react-dom";
import TaskDuplicatePopup from "./TaskDuplicatePopup";

export default function TaskDuplicatePopupWrapper(props) {

  return (
    !props.insideParent ?
      createPortal((
        <TaskDuplicatePopup
          taskName={props.taskName}
          taskComm={props.taskComm}
          taskAddit={props.taskAddit}
          taskCheck={props.taskCheck}
          colId={props.colId}
          taskId={props.taskId}
          handleClickClose={props.handleClickClose}
          subtasks={props.subtasks}
        />
      ), document.getElementById('modal_root'))
      :
      <TaskDuplicatePopup
        taskName={props.taskName}
        taskComm={props.taskComm}
        taskAddit={props.taskAddit}
        taskCheck={props.taskCheck}
        colId={props.colId}
        taskId={props.taskId}
        handleClickClose={props.handleClickClose}
        subtasks={props.subtasks}
      />
  )
}
