import React from "react";
import { useEffect } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectChecklists } from "../../../../../../../../../../redux/BoardPage/сolumnSelector";
import { getChecklists } from "../../../../../../../../../../redux/BoardPage/сolumnsReducer";
import CheckListPopup from "./CheckListPopup";

export default function CheckListPopupWrapper(props) {

  const dispatch = useDispatch();
  const checklists = useSelector(selectChecklists)

  useEffect(() => {
    dispatch(getChecklists(props.boardId))
  }, [])

  return (
    !props.insideParent ?
      createPortal((
        <CheckListPopup
          handleClickClose={props.handleClickClose}
          taskId={props.taskId}
          checklists={checklists}
        />
      ), document.getElementById('modal_root'))
      :
      <CheckListPopup
        handleClickClose={props.handleClickClose}
        taskId={props.taskId}
        checklists={checklists}
      />
  )
}
