import classNames from 'classnames';
import React from 'react';
import styles from './fulltimer.module.css';
import TimerControls from './TimerControls/TimerControls';

export default function FullTimer(props) {

  return (
    <div className={classNames('flex', styles.full)}>
      <div className={styles.controls}>
        <TimerControls
          timerStatus={props.timerStatus}
          startTimer={props.startTimer}
          pauseTimer={props.pauseTimer}
          time={props.time}
        />
      </div>
      <div className={styles.description}>
        <p>Неделя: 00:00:00</p>
        <p>Месяц: 00:00:00</p>
      </div>
    </div>
  );
}
