import React, { useState } from 'react';
import styles from './comments.module.css';
import classNames from 'classnames';
import CommentItem from './CommentItem/CommentItem';
import CommentsFilter from './CommentsFilter/CommentsFilter';
import AddCommentForm from './AddCommentForm/AddCommentForm';
import FilterIcon from '../../../svgIcons/FilterIcon';

export default function Comments(props) {
  const [isOpenPopup, setIsOpenPopup] = useState(false);
  const handleClickOpen = () => {
    setIsOpenPopup(true)
    document.body.classList.add('modal-show');
  }
  const handleClickClose = () => {
    setIsOpenPopup(false)
    document.body.classList.remove('modal-show');
  }

  return (
    <div className={classNames(!props.inTask && 'contentBlock', !props.inTask && 'shadow')}>
      <div className={classNames('flex', 'blockHeader', styles.commentsHeader)}>
        <h2 className={classNames('blockTitle', props.inTask && styles.title)}>{props.inTask ? 'Чат задачи' : 'Комментарии'}</h2>
        {
          props.hasFilter &&
          <button
            onClick={handleClickOpen}
            className={classNames('flex', 'iconBtn', props.filtered && 'activeFilter')}
          >
            <FilterIcon />
          </button>
        }
      </div>
      {
        !props.readOnly &&
        <AddCommentForm
          targetField={props.targetField}
          targetId={props.targetId}
          submitFunc={props.submitFunc}
          insideParent={props.insideParent}
          access={props.access}
          filteredData={props.filteredData}
          filtered={props.filtered}
          withoutFiles={props.withoutFiles}
        />
      }

      <div>
        {
          (props.comments && props.comments?.length > 0) ?
            props.comments.map((item) =>
              <div
                key={item.id}
                className={styles.commentItem}
              >
                <CommentItem
                  hasPinned={props.hasPinned}
                  item={item}
                  access={props.access}
                  handleEdit={props.handleEdit}
                  insideParent={props.insideParent}
                  handleDelete={props.handleDelete}
                  handleEditPinned={props.handleEditPinned}
                  getComments={props.getComments}
                  getFilteredComments={props.getFilteredComments}
                  filteredData={props.filteredData}
                  filtered={props.filtered}
                  forComment={props.forComment}
                  inTask={props.inTask}
                  withoutFiles={props.withoutFiles}
                  readOnly={props.readOnly}
                />
              </div>
            )
            :
            <div className={styles.commentEmpty}>Комментарии отсутсвуют</div>
        }
      </div>
      {
        isOpenPopup && <CommentsFilter
          handleClickClose={handleClickClose}
          submitText={'Применить'}
          popupHeader={'Фильтр по комментариям'}
          targetId={props.targetId}
          targetText={props.targetField}
          getComments={props.getComments}
          getFilteredComments={props.getFilteredComments}
          filteredData={props.filteredData}
          filtered={props.filtered}
        />
      }
    </div>
  );
}
