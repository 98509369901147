import { profileAPI } from "../../API/api";
import { mapObj } from "../../utils/objectHelpers";

const SET_USER_COMMENTS = 'SET_USER_COMMENTS';
const CREATE_USER_COMMENT = 'CREATE_USER_COMMENT';
const EDIT_USER_COMMENT = 'EDIT_USER_COMMENT';
const DELETE_USER_COMMENT = 'DELETE_USER_COMMENT';

let initialState = {
  comments: [],
  isFetching: false
};

export const userCommentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_COMMENTS:
      return {
        ...state,
        comments: action.comments,
      }
    case CREATE_USER_COMMENT:
      return {
        ...state,
        comments: [...state.comments, action.newComment],
      }
    case EDIT_USER_COMMENT:
      return {
        ...state,
        comments: mapObj(state.comments, action.commentId, 'id', action.newObj)
      }
    case DELETE_USER_COMMENT:
      return {
        ...state,
        comments: state.comments.filter((el) => el.id !== action.commentId)
      }
    default: return { ...state };
  }
}

const setComments = (comments) => ({ type: SET_USER_COMMENTS, comments });
const setAddComment = (newComment) => ({ type: CREATE_USER_COMMENT, newComment });
const setEditComment = (commentId, newObj) => ({ type: EDIT_USER_COMMENT, commentId, newObj });
const setDeleteComment = (commentId) => ({ type: DELETE_USER_COMMENT, commentId });

export const getUserComments = (userId, orgId) => async (dispatch) => {
  await profileAPI.getUserComments(userId, orgId)
    .then(response => dispatch(setComments(response.data)))
    .catch(err => console.log(err))
}

export const createUserComment = (
  {
    userId: userId,
    author: author,
    text: text,
    display_to_customer: display_to_customer,
  }
) => async (dispatch) => {
  await profileAPI.createUserComments(
    {
      userId: userId,
      author: author,
      text: text,
      display_to_customer: display_to_customer,
    }
  )
    .then(response => dispatch(setAddComment(response.data)))
    .catch(err => console.log(err))
}

export const editUserComment = (
  {
    id: id,
    text: text,
    display_to_customer: display_to_customer,
  }
) => async (dispatch) => {
  await profileAPI.editUserComment(
    {
      id: id,
      text: text,
      display_to_customer: display_to_customer,
    }
  )
    .then(response => dispatch(setEditComment(id, response.data)))
    .catch(err => console.log(err))
}

export const deleteUserCommentItem = (id) => async (dispatch) => {
  await profileAPI.deleteUserCommentItem(id)
    .then(() => dispatch(setDeleteComment(id)))
}
