import { profileAPI } from "../../API/api";

const SET_USER = 'SET_USER'

let initialState = {
  user: {},
  isFetching: false
};

export const userProfile = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: action.user
      }
    default: return { ...state };
  }
}

const setUser = (user) => ({ type: SET_USER, user });

export const getUserProfile = (userId, orgId) => async (dispatch) => {
  await profileAPI.getUserProfile(userId, orgId)
    .then(response => dispatch(setUser(response.data)))
    .catch(err => console.log(err))
}
