import classNames from "classnames";
import React, { useState } from "react";
import { socketFileApi } from "../../../../../../../../../../API/api";
import { socket } from "../../../../../../../../../../API/socket";
import DeleteIcon from "../../../../../../../../../../svgIcons/DeleteIcon";
import EditSmallIcon from "../../../../../../../../../../svgIcons/EditSmallIcon";
import ConfirmWindow from "../../../../../../../../../common/ConfirmWindow/ConfirmWindow";
import styles from './banner.module.css';

export default function Banner(props) {

  const [showEdit, setShowEdit] = useState(false);
  const [isOpenConfirmPopup, setIsOpenConfirmPopup] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();

    socketFileApi.setTaskBanner(props.taskId, e.target.files[0])
      .then(() => {
        socket.emit('SetTaskBanner', {
          "task_id": props.taskId,
          "file_uploladed": true
        })
      })
  }

  const handleDelete = () => {
    socket.emit('SetTaskBanner', {
      "task_id": props.taskId,
    })
    setIsOpenConfirmPopup(false)
  }

  return (
    <div
      onMouseEnter={() => setShowEdit(true)}
      onMouseLeave={() => setShowEdit(false)}
      className={classNames('flex', 'imageBox', styles.banner)}
    >
      <img src={props.img} alt="banner" />
      {
        showEdit && !props.readOnly &&
        <div className={classNames('flex', 'iconBtnGroup', styles.editBtns)}>
          <button
            className={classNames('flex', 'iconBtn', styles.delete)}
            onClick={() => {
              setIsOpenConfirmPopup(true)
            }}
          >
            <DeleteIcon />
          </button>
          <div className={classNames('fileInputWrapper', 'flex', styles.file)}>
            <input
              type='file'
              accept=".jpg, .jpeg, .png"
              className={'fileInput'}
              name='banner'
              id='banner'
              onChange={(e) => {
                handleChange(e)
              }}
            >
            </input>
            <label
              className={classNames('flex', 'fileLabel', styles.fileLabel)}
              htmlFor="banner"
            >
              <EditSmallIcon />
            </label>
          </div>
        </div>
      }
      {
        isOpenConfirmPopup &&
        <ConfirmWindow
          insideParent={true}
          title={'Удалить баннер?'}
          onDelete={handleDelete}
          closeDelete={() => {
            setIsOpenConfirmPopup(false)
          }}
        />
      }
    </div>
  )
}
