import React from 'react';
import styles from './userinfo.module.css';
import classNames from 'classnames';
import { editNameWithoutDots } from '../../../utils/nameEditor';

export default function UserInfo(props) {

  return (
    <div className={classNames('flex', styles.user)}>
      <div
      className={classNames('imageBox', styles.avatar, props.isFull && styles.fullNav)}
      style={{backgroundColor:`${props.color}`}}
      >
        {props.avatar ?
          <img src={props.avatar} alt="ava" />
          :
          <p className={styles.initial}>
            {editNameWithoutDots(props.last_name)}{editNameWithoutDots(props.first_name)}
          </p>
        }
      </div>
      <div className={classNames(styles.userInfo, props.isFull && styles.fullNav)}>
        <div>
          <span className={styles.name}>{props.first_name}</span>
          &nbsp;
          <span className={styles.name}>{props.last_name}</span>
        </div>
        <p className={classNames(styles.name, styles.email)}>{props.email}</p>
      </div>
    </div>
  );
}
