import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import styles from './boardmovepopup.module.css';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../../../../../svgIcons/CloseIcon";
import Select from "react-select";
import { selectProjects } from "../../../../../../redux/projectListSelector";
import { deleteBoard, sendBoard } from "../../../../../../redux/boardsListReducer";
import { useNavigate } from "react-router-dom";

export default function BoardMovePopup(props) {

  const ref = useRef(null)

  const outsideHandleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      props.handleClickClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', outsideHandleClick);
    return () => { }
  }, [])

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const projects = useSelector(selectProjects);

  let optionsProjects = [];
  projects?.filter(item => item.id !== props.item.project).map((item) => {
    optionsProjects.push({
      value: `${item?.id}`, label: `${item?.name}`
    })
  })

  const {
    clearErrors,
    setError,
    getValues,
    control,
    setValue,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const moveBoard = (data) => {
    dispatch(sendBoard(
      {
        boardId: props.item.id,
        projectId: Number(data.project),
      }
    ))
      .then(dispatch(deleteBoard(props.item.id)))
      .then(() => {
        props.handleClickClose()
        document.body.classList.remove('modal-show')
      })
      .then(() => {
        if ((window.location.href).includes(`board/${props.item.id}`)) {
          navigate(`/projects/${data.project}`)
        }
      })
  }

  const copyBoard = (data) => {
    dispatch(sendBoard(
      {
        boardId: props.item.id,
        projectId: Number(data.project),
      }
    ))
      .then(() => {
        props.handleClickClose()
        document.body.classList.remove('modal-show')
      })
  }

  const onSubmit = (data => {
    props.moveKey === 'move'
      ?
      moveBoard(data)
      :
      copyBoard(data)
  })

  return createPortal((
    <div className={classNames('flex', 'popup')}>
      <div className={classNames('popupWrapper')}>
        <div className={classNames('popupWindow', 'shadow')} ref={ref}>
          <h3 className={classNames('popupHeader')}>{props.popupHeader}</h3>
          <form
            className={classNames('flex', 'popupform', 'filter')}
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => {
              clearErrors('serverError');
            }}
          >
            <div className={classNames('flex', 'popupInputBox')}>
              <p className={classNames('popupLabel')}>Выбор проекта</p>
              <div
                onMouseDown={e => {
                  e.stopPropagation()
                }}
                className={'popupInputAllView'}
              >
                <Controller
                  control={control}
                  name='project'
                  render={({ field: { value, onChange } }) => (
                    <Select
                      isSearchable={false}
                      isClearable={true}
                      maxMenuHeight={180}
                      menuPlacement={'auto'}
                      placeholder='Выбрать'
                      classNamePrefix="react-select"
                      className={classNames('react-select-container')}
                      options={optionsProjects}
                      value={value ? optionsProjects.find((с) => с.value === value) : ''}
                      onChange={(val) => onChange(val?.value)}
                    />
                  )}
                  rules={{ required: 'Выберите проект' }}
                />
              </div>
            </div>
            <div className={classNames('popupBtnsWrapper')}>
              <button
                className={'btn'}
                type='submit'
                disabled={!isValid}
              >
                {props.submitText}
              </button>
            </div>
          </form>
          <button
            className={classNames('closeBtn', 'iconBtn', 'flex')}
            onClick={props.handleClickClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div >
  ), document.getElementById('modal_root'))
}
