import React from 'react';
import styles from './usercontrols.module.css';
import classNames from 'classnames';
import { useState } from 'react';
import UserPopup from './UserPopup/UserPopup';
import UserInfo from '../../../common/UserInfo/UserInfo';

export default function UserControls(props) {

  const [isOpenUser, setIsOpenUser] = useState(false)

  const handleClickOpen = () => {
    setIsOpenUser(true)
  }

  return (
    <div
      onMouseEnter={() => setIsOpenUser(true)}
      onMouseLeave={() => setIsOpenUser(false)}
      className={classNames('flex', styles.controls)}
    >
      {
        props.width <= 768
          ?
          (
            props.isFull &&
            <div
              className={classNames('flex', styles.userBtn)}
            >
              <UserInfo
                avatar={props.me?.user?.avatar}
                first_name={props.me?.user?.first_name}
                last_name={props.me?.user?.last_name}
                email={props.me?.user?.email}
                isFull={props.isFull}
                color={props.me?.user?.avatar_color}
              />
            </div>
          )
          :
          <div
            className={classNames('flex', styles.userBtn)}
          >
            <UserInfo
              avatar={props.me?.user?.avatar}
              first_name={props.me?.user?.first_name}
              last_name={props.me?.user?.last_name}
              email={props.me?.user?.email}
              isFull={props.isFull}
              color={props.me?.user?.avatar_color}
            />
          </div>
      }
      {isOpenUser &&
        <UserPopup
          me={props.me}
          handleClick={props.handleClick}
          isOpen={isOpenUser}
        />
      }
    </div>
  );
}
