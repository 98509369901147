import React from 'react';
import { useEffect, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectMe } from '../../../../redux/authSelectors';
import { getDepartments } from '../../../../redux/CompanyPage/companyDepartmentsReducer';
import { getCompanyInfo } from '../../../../redux/CompanyPage/companyInfoReducer';
import { selectCompanyInfo, selectDepartments } from '../../../../redux/CompanyPage/сompanyPageSelector';
import { getArchivedProjects } from '../../../../redux/projectListReducer';
import { selectArchivedProjects, selectProjects } from '../../../../redux/projectListSelector';
import { getClients, getInvites, getUsers } from '../../../../redux/usersReducer';
import { selectClients, selectUsers } from '../../../../redux/usersSelector';
import { generateKey } from '../../../../utils/generateKey';
import Preloader from '../../../common/Preloader/Preloader';

const Company = lazy(() => import('./Company'));

export default function CompanyContainer(props) {
  const dispatch = useDispatch();
  const me = useSelector(selectMe);

  useEffect(() => {
    if (me && me?.active_organization?.id) {
      dispatch(getArchivedProjects({
        organization_ids: me?.active_organization?.id
      }))
      dispatch(getUsers(me?.active_organization?.id))
      dispatch(getClients(me?.active_organization?.id))
      dispatch(getDepartments(me?.active_organization?.id))
      dispatch(getCompanyInfo(me?.active_organization?.id))
      dispatch(getInvites(me?.active_organization?.id))
    }
  }, [dispatch, me])

  const projects = useSelector(selectProjects);
  const archivedProjects = useSelector(selectArchivedProjects);
  const workers = useSelector(selectUsers);
  const departments = useSelector(selectDepartments);
  const info = useSelector(selectCompanyInfo);
  const clients = useSelector(selectClients)

  return (
    <Suspense fallback={<Preloader />}>
      <Company
        info={info}
        projects={projects}
        archivedProjects={archivedProjects}
        workers={workers}
        clients={clients}
        departments={departments}
        me={me}
      />
    </Suspense>
  );
}
