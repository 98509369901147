import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectMe } from '../../../../redux/authSelectors';
import { getTariff } from '../../../../redux/Tariff/tariffReducer';
import Pay from './Pay';

export default function PayContainer(props) {

  const me = useSelector(selectMe);
  const dispatch = useDispatch();

  useEffect(() => {
    (me && me?.active_organization?.id) && dispatch(getTariff({organization_id: me?.active_organization?.id}))
  }, [dispatch, me])

  return (
    <Pay />
  );
}
