import { useDispatch, useSelector } from 'react-redux';
import React from 'react';
import TaskList from './TaskList';
import { selectMyTasks, selectTimers } from '../../../../../redux/Tracker/trackerSelector';
import { useEffect } from 'react';
import { getMyTasks } from '../../../../../redux/Tracker/myTaskReducer';
import { selectMe } from '../../../../../redux/authSelectors';
import { getTimers } from '../../../../../redux/Tracker/timerReducer';

export default function TaskListContainer(props) {

  const dispatch = useDispatch();
  const tasks = useSelector(selectMyTasks);
  const timers = useSelector(selectTimers);

  useEffect(() => {
    dispatch(getMyTasks())
    dispatch(getTimers())
  }, [dispatch])

  return (
    <TaskList
      tasks={tasks}
      timers={timers}
    />
  );
}
