import React from 'react';

export default function ExitIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.09427 20C3.94479 19.9641 3.79375 19.9313 3.64479 19.8912C2.98253 19.7169 2.39642 19.3287 1.97763 18.7869C1.55884 18.2451 1.33087 17.5801 1.32917 16.8953C1.32465 12.3078 1.32465 7.72049 1.32917 3.13334C1.33177 2.31393 1.65815 1.52877 2.23717 0.94897C2.81619 0.36917 3.60091 0.0417346 4.42031 0.0380279C7.35781 0.0302154 10.2953 0.0349029 13.2328 0.0380279C13.7906 0.0380279 14.1995 0.433861 14.2031 0.965632C14.2063 1.50678 13.7958 1.90782 13.2281 1.90834C10.3497 1.90834 7.47083 1.90834 4.59167 1.90834C3.70625 1.90834 3.19896 2.41199 3.19844 3.29428V16.7432C3.19844 17.624 3.70625 18.1281 4.59271 18.1281C7.47118 18.1281 10.35 18.1281 13.2292 18.1281C13.7255 18.1281 14.1203 18.4469 14.1922 18.8964C14.2703 19.3849 14.0104 19.7979 13.5208 19.9635C13.4975 19.9736 13.4751 19.9854 13.4536 19.999L4.09427 20Z" fill="#3E5196" />
      <path d="M15.4651 10.9536H15.2568C12.904 10.9536 10.5517 10.9536 8.2 10.9536C7.68698 10.9536 7.3 10.6333 7.22292 10.1536C7.13437 9.60414 7.56979 9.09112 8.14271 9.08434C8.80521 9.07705 9.46823 9.08434 10.1312 9.08434H15.5068C15.4318 9.00362 15.3875 8.95205 15.3396 8.90414C14.4625 8.02427 13.5854 7.14615 12.7083 6.26976C12.4417 6.00466 12.3313 5.6932 12.4234 5.32966C12.4634 5.17315 12.5434 5.02973 12.6555 4.91345C12.7676 4.79717 12.908 4.71203 13.0629 4.66639C13.2179 4.62075 13.382 4.61617 13.5393 4.6531C13.6965 4.69003 13.8415 4.76721 13.9599 4.87705C14.0083 4.92028 14.0531 4.96716 14.0995 5.01299L18.3448 9.25778C18.8484 9.76143 18.8469 10.2755 18.3411 10.7817C16.9123 12.2106 15.483 13.6396 14.0531 15.0687C13.7792 15.3432 13.4656 15.4703 13.0823 15.3646C12.9309 15.3241 12.7923 15.246 12.6792 15.1376C12.5661 15.0291 12.4823 14.8939 12.4355 14.7443C12.3888 14.5948 12.3806 14.4359 12.4117 14.2823C12.4428 14.1288 12.5122 13.9856 12.6135 13.8661C12.6656 13.8062 12.7177 13.7521 12.775 13.6974C13.625 12.8467 14.4757 11.9968 15.3271 11.1479C15.3792 11.0958 15.4365 11.0562 15.4917 11.0104L15.4651 10.9536Z" fill="#3E5196" />
    </svg>
  );
}
