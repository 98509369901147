import { companyAPI } from "../../API/api";
import { mapObj } from "../../utils/objectHelpers";

const SET_DEPARTMETS = 'SET_DEPARTMETS';
const ADD_DEPARTMET_ITEM = 'ADD_DEPARTMET_ITEM';
const EDIT_DEPARTMET_ITEM = 'EDIT_DEPARTMET_ITEM';
const DELETE_DEPARTMET_ITEM = 'DELETE_DEPARTMET_ITEM';

let initialState = {
  departments: [],
  isFetching: false,
};

export const departmentsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DEPARTMETS:
      return {
        ...state,
        departments: action.departments,
      }
    case ADD_DEPARTMET_ITEM:
      return {
        ...state,
        departments: [...state.departments, action.newItem],
      }
    case EDIT_DEPARTMET_ITEM:
      return {
        ...state,
        departments: mapObj(state.departments, action.planId, 'id', action.newObj)
      }
    case DELETE_DEPARTMET_ITEM:
      return {
        ...state,
        departments: state.departments.filter((el) => el.id !== action.departmentId)
      }
    default: return { ...state };
  }
}

const setDepartments = (departments) => ({ type: SET_DEPARTMETS, departments });
// const setAddPlan = (newItem) => ({ type: ADD_PLAN_ITEM, newItem });
// const setEditPlan = (planId, newObj) => ({ type: EDIT_PLAN_ITEM, planId, newObj });

export const getDepartments = (id) => async (dispatch) => {
  await companyAPI.getDepartments(id)
    .then(response => dispatch(setDepartments(response.data)))
    .catch(err => console.log(err))
}

export const addDepartment = (
  {
    companyId: companyId,
    parentDep: parentDep,
    team: team,
    name: name,
  }
) => async (dispatch) => {
  await companyAPI.createDepartment(
    {
      companyId: companyId,
      parentDep: parentDep,
      team: team,
      name: name,
    }
  )
    // .then(response => dispatch(setAddPlan(response.data)))
    .catch(err => console.log(err))
}

export const editDepartment = (
  {
    depId: depId,
    companyId: companyId,
    parentDep: parentDep,
    team: team,
    name: name,
  }
) => async (dispatch) => {
  await companyAPI.editDepartment(
    {
      depId: depId,
      companyId: companyId,
      parentDep: parentDep,
      team: team,
      name: name,
    }
  )
    // .then(response => dispatch(setEditPlan(id, response.data)))
    .catch(err => console.log(err))
}

export const deleteDepartment = (companyId,id) => async (dispatch) => {
  await companyAPI.deleteDepartment(companyId,id)
    // .then(() => dispatch(setDeletePlan(id)))
}

