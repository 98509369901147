import React from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import { selectIsAuth } from "../redux/authSelectors";
import { useSelector } from 'react-redux';

export default function PrivateRoute({
  children,
}) {

  const url = useLocation();

  const isAuth = useSelector(selectIsAuth);

  return isAuth || url.pathname.includes('accept-invitation') ? children : <Navigate to="/login" />;
};
