import React from 'react';

export default function HomeIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2013_60)">
        <path d="M19.2675 7.55751L12.9467 1.23584C12.1644 0.455835 11.1047 0.0178223 10 0.0178223C8.89529 0.0178223 7.83564 0.455835 7.05334 1.23584L0.73251 7.55751C0.499531 7.789 0.314818 8.06443 0.189079 8.36783C0.0633394 8.67124 -0.000925334 8.99658 1.00662e-05 9.32501V17.5058C1.00662e-05 18.1689 0.263402 18.8048 0.732243 19.2736C1.20108 19.7425 1.83697 20.0058 2.50001 20.0058H17.5C18.1631 20.0058 18.7989 19.7425 19.2678 19.2736C19.7366 18.8048 20 18.1689 20 17.5058V9.32501C20.0009 8.99658 19.9367 8.67124 19.8109 8.36783C19.6852 8.06443 19.5005 7.789 19.2675 7.55751ZM12.5 18.3392H7.50001V15.0608C7.50001 14.3978 7.7634 13.7619 8.23224 13.2931C8.70109 12.8242 9.33697 12.5608 10 12.5608C10.6631 12.5608 11.2989 12.8242 11.7678 13.2931C12.2366 13.7619 12.5 14.3978 12.5 15.0608V18.3392ZM18.3333 17.5058C18.3333 17.7269 18.2455 17.9388 18.0893 18.0951C17.933 18.2514 17.721 18.3392 17.5 18.3392H14.1667V15.0608C14.1667 13.9558 13.7277 12.896 12.9463 12.1146C12.1649 11.3332 11.1051 10.8942 10 10.8942C8.89494 10.8942 7.83513 11.3332 7.05373 12.1146C6.27233 12.896 5.83334 13.9558 5.83334 15.0608V18.3392H2.50001C2.279 18.3392 2.06703 18.2514 1.91075 18.0951C1.75447 17.9388 1.66668 17.7269 1.66668 17.5058V9.32501C1.66745 9.10416 1.75517 8.8925 1.91084 8.73584L8.23168 2.41668C8.70143 1.94912 9.33723 1.68663 10 1.68663C10.6628 1.68663 11.2986 1.94912 11.7683 2.41668L18.0892 8.73834C18.2442 8.89438 18.3319 9.10503 18.3333 9.32501V17.5058Z" fill="#3E5196" />
      </g>
      <defs>
        <clipPath id="clip0_2013_60">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
 