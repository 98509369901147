import React from 'react';
import styles from './filecardlist.module.css';
import classNames from 'classnames';
import FileCard from './FileCard/FileCard';
import { editFileName } from '../../../utils/fileNameEditor';
import FileImageCard from './FileCard/FileImageCard/FileImageCard';

export default function FileCardList(props) {

  return (
    <div className={styles.wrapper}>
      <div className={classNames('flex', styles.cardList)}>
        {
          props.files?.filter(item => !['.jpg', '.jpeg', '.png'].includes(editFileName(item.name).toLowerCase())).map(item =>
            <FileCard
              key={item.id}
              item={item}
              // if file has menu
              fileMenu={props.fileMenu}
              fileMenuDoc={props.fileMenuDoc}
              // props in comments
              withoutMenu={props.withoutMenu}
              editFile={props.editFile}
              handleDeleteFile={props.handleDeleteFile}
              // files array
              files={props.files}
            />
          )
        }
      </div>
      <div className={classNames('flex', styles.cardList)}>
        {
          props.files?.filter(item => ['.jpg', '.jpeg', '.png'].includes(editFileName(item.name).toLowerCase())).map(item =>
            <FileImageCard
              key={item.id}
              item={item}
              // if file has menu
              fileMenu={props.fileMenu}
              fileMenuImg={props.fileMenuImg}
              // props in comments
              withoutMenu={props.withoutMenu}
              editFile={props.editFile}
              handleDeleteFile={props.handleDeleteFile}
              // files array
              files={props.files}
              // for image popup
              insideParent={props.insideParent}
            />
          )
        }
      </div>
    </div>
  );
}
