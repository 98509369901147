export function copyLink(copyLink, message) {
  const messageWindow = document.createElement("div");
  messageWindow.classList.add('messageCopy')

  navigator.clipboard.writeText(copyLink)
  .then(() =>  {
    messageWindow.textContent = `Ссылка на ${message} успешно скопирована`
    document.body.appendChild(messageWindow);
  })
  .catch((err) => {
    messageWindow.textContent = `Не удалось скопировать ссылку`
    messageWindow.classList.add('messageCopy', 'error')
    document.body.appendChild(messageWindow);
  })
  .then(() => {
    setTimeout(() => messageWindow.remove(), 2000)
  })
}
