import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import styles from './tagpopup.module.css';
import { Controller, useForm } from 'react-hook-form';
import Select from "react-select";
import CloseIcon from "../../../../../../../../../../svgIcons/CloseIcon";
import AddIcon from "../../../../../../../../../../svgIcons/AddIcon";
import { useState } from "react";
import { socket } from "../../../../../../../../../../API/socket";

export default function TagPopup(props) {

  const ref = useRef(null)

  const outsideHandleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      props.handleClickClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', outsideHandleClick);
    return () => { }
  }, [])

  const [color, setColor] = useState(props.edit ? props.color : null)
  const [colorFromInput, setColorFromInput] = useState(props.edit ? props.color : null)
  const [isCreatedForm, setIsCreatedForm] = useState(false)

  let optionsTags = [];
  props.allTagsList?.map(item =>
    optionsTags.push({
      value: `${item.id}`,
      label: `${item.name}`
    }))

  const colors = [
    {
      name: '#FF0000',
    },
    {
      name: '#008000',
    },
    {
      name: '#0000FF',
    },
    {
      name: '#800080',
    },
    {
      name: '#808080',
    },
    {
      name: '#FFA500',
    },
  ]

  const {
    clearErrors,
    setError,
    getValues,
    control,
    setValue,
    register,
    reset,
    handleSubmit,
    formState: { errors, isValid }
  } = useForm({
    mode: 'onChange',
  });

  const createTag = (data) => {
    socket.emit('CreateLabel', {
      'taskId': props.taskId,
      'name': data.name,
      'color': color || ''
    })
    props.handleClickClose()
  }

  const addTagToTask = (data) => {
    socket.emit('AddLabel', {
      'taskId': props.taskId,
      'labelId': data.tag,
    })
    props.handleClickClose()
  }
  const update = (data) => {
    socket.emit('UpdateLable', {
      'lable_id': props.tagId,
      'name': data.name,
      'color': color || ''
    })
    props.handleClickClose()
  }

  const onSubmit = (data => {
    props.edit ?
      update(data)
      :
      isCreatedForm ?
        createTag(data) :
        addTagToTask(data)
  })

  return (
    <div className={classNames('flex', 'popup')}>
      <div className={classNames('popupWrapper')}>
        <div className={classNames('popupWindow', 'shadow', styles.tagWrap)} ref={ref}>
          <h3 className={classNames('popupHeader')}>
            {props.edit ? 'Редактировать метку' : !isCreatedForm ? 'Добавить метку' : 'Создать новую метку'}
          </h3>
          <form
            className={classNames('flex', 'popupform', 'filter')}
            onSubmit={handleSubmit(onSubmit)}
            onChange={() => {
              clearErrors('serverError');
            }}
          >
            {
              (isCreatedForm || props.edit) &&
              <div className={classNames('flex', 'popupInputBox')}>
                <p className={classNames('popupLabel')}>Название</p>
                <input
                  className={classNames('input')}
                  type='text'
                  name='name'
                  defaultValue={props.edit && props.name}
                  placeholder='Название'
                  {...register('name',
                    {
                      required: 'Введите название',
                      minLength: {
                        value: 5,
                        message: 'Минимум 5 символов'
                      },
                    })}
                />
              </div>
            }
            {
              (!isCreatedForm && !props.edit) &&
              <div className={classNames('flex', 'popupInputBox')}>
                <p className={classNames('popupLabel')}>Выбор метки</p>
                <div
                  onMouseDown={e => {
                    e.stopPropagation()
                  }}
                  className={'popupInputAllView'}
                >
                  <Controller
                    control={control}
                    name='tag'
                    render={({ field: { value, onChange } }) => (
                      <Select
                        isSearchable={false}
                        isClearable={true}
                        maxMenuHeight={180}
                        menuPlacement={'auto'}
                        placeholder='Выбрать'
                        classNamePrefix="react-select"
                        className={classNames('react-select-container')}
                        options={optionsTags}
                        value={value ? optionsTags.find((с) => с.value === value) : ''}
                        onChange={(val) => onChange(val?.value)}
                      />
                    )}
                    rules={{ required: 'Выберите проект' }}
                  />
                </div>
              </div>
            }
            {(isCreatedForm || props.edit) &&
              <div className={classNames('flex', 'popupInputBox')}>
                <p className={classNames('popupLabel')}>Цвет фона метки</p>
                <div className={classNames('flex', styles.colorsMenu)}>
                  <div className={classNames('flex', styles.colorsWrapper)}>
                    <button
                      type='button'
                      className={classNames('btn', 'btnTransparent', styles.withoutColorBtn)}
                      onClick={() => {
                        setColorFromInput(null)
                        setColor(null)
                      }}
                    >
                      Без фона
                    </button>
                    {
                      colors.map((elem, index) =>
                        <div
                          key={index}
                          className={styles.colorInputBox}
                        >
                          <input
                            type='radio'
                            name='radioColor'
                            value={elem.name}
                            id={elem.name + index}
                            className={classNames('iconBtn', styles.colorInput)}
                            onChange={e => {
                              setColor(e.target.value)
                              setColorFromInput(null)
                            }}
                            checked={elem.name === color}
                          >
                          </input>
                          <label
                            className={styles.colorLabel}
                            style={{ backgroundColor: `${elem.name}` }}
                            htmlFor={elem.name + index}
                          >
                          </label>
                        </div>
                      )
                    }
                  </div>
                  <div className={classNames(styles.colorInputWrapper, styles.colorInputBox)}>
                    <input
                      type='color'
                      id='inputColor'
                      className={classNames('flex', 'iconBtn', styles.colorAddBtn)}
                      onChange={e => {
                        setColorFromInput(e.target.value)
                        setColor(e.target.value)
                      }}
                      value={props.edit && props.color}
                    />
                    <label htmlFor='inputColor'>
                      <AddIcon />
                    </label>
                  </div>
                  {
                    colorFromInput &&
                    <div
                      style={{ backgroundColor: `${colorFromInput}` }}
                      className={classNames(styles.colorFromInput, styles.colorInputBox)}
                    >
                    </div>
                  }

                </div>
              </div>
            }
            {
              (!isCreatedForm && !props.edit) &&
              <div className={styles.createNewWrap}>
                <button
                  onClick={() => {
                    setIsCreatedForm(true)
                    setColorFromInput(null)
                    setColor(null)
                  }}
                  className={classNames('btn', 'btnTransparent', styles.createNewColor)}
                  type='button'
                >
                  Создать новую
                </button>
              </div>
            }
            <div className={classNames('popupBtnsWrapper')}>
              <button
                className={'btn'}
                type='submit'
                disabled={!isValid}
              >
                {props.edit ? 'Изменить' : !isCreatedForm ? 'Добавить' : 'Создать'}
              </button>
            </div>
          </form>
          <button
            className={classNames('closeBtn', 'iconBtn', 'flex')}
            onClick={props.handleClickClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
    </div >
  )
}
