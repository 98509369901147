export function editTimer(timer) {
  const daysRes = timer > 0 ? Math.floor(timer / 60 / 60 / 24) : 0;
  const hoursRes = timer > 0 ? Math.floor(timer / 60 / 60) % 24 : 0;
  const minutesRes = timer > 0 ? Math.floor(timer / 60) % 60 : 0;
  const secondsRes = timer > 0 ? Math.floor(timer) % 60 : 0;

  const days = daysRes;
  const hours = hoursRes < 10 ? '0' + hoursRes : hoursRes;
  const minutes = minutesRes < 10 ? '0' + minutesRes : minutesRes;
  const seconds = secondsRes < 10 ? '0' + secondsRes : secondsRes;

  return `${daysRes ? days +'дн' : ''} ${daysRes ? hours + 'ч' : hoursRes ? hours + 'ч' : ''} ${!daysRes ? minutes + 'мин' : ''} ${!daysRes && !hoursRes ? seconds + 'c' : ''}`
}
