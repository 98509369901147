import React from 'react';

export default function UnlockRoundIcon(props) {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29ZM15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#3E5196" />
      <path d="M17.9167 12.1667H12.0834V11.5834C12.0832 10.9367 12.298 10.3083 12.6939 9.79694C13.0898 9.28561 13.6444 8.92035 14.2705 8.7586C14.8966 8.59684 15.5588 8.64775 16.1528 8.90334C16.7468 9.15892 17.2391 9.60467 17.5522 10.1705C17.5894 10.2375 17.6394 10.2965 17.6994 10.3442C17.7594 10.3918 17.8282 10.4272 17.9018 10.4483C17.9755 10.4694 18.0526 10.4757 18.1287 10.467C18.2048 10.4583 18.2785 10.4346 18.3455 10.3974C18.4125 10.3602 18.4715 10.3102 18.5192 10.2502C18.5668 10.1902 18.6022 10.1214 18.6233 10.0478C18.6443 9.9741 18.6507 9.897 18.642 9.82088C18.6332 9.74475 18.6096 9.67109 18.5724 9.6041C18.1339 8.81212 17.4447 8.18831 16.613 7.83072C15.7814 7.47313 14.8545 7.40205 13.978 7.62864C13.1016 7.85524 12.3252 8.36666 11.7711 9.08252C11.217 9.79838 10.9164 10.6781 10.9167 11.5834V12.414C10.3972 12.6408 9.955 13.014 9.64421 13.4881C9.33341 13.9621 9.16749 14.5165 9.16675 15.0834V18.5834C9.16767 19.3566 9.47526 20.0979 10.022 20.6447C10.5688 21.1915 11.3102 21.4991 12.0834 21.5H17.9167C18.69 21.4991 19.4313 21.1915 19.9781 20.6447C20.5249 20.0979 20.8325 19.3566 20.8334 18.5834V15.0834C20.8325 14.3101 20.5249 13.5688 19.9781 13.022C19.4313 12.4752 18.69 12.1676 17.9167 12.1667ZM19.6667 18.5834C19.6667 19.0475 19.4824 19.4926 19.1542 19.8208C18.826 20.149 18.3809 20.3334 17.9167 20.3334H12.0834C11.6193 20.3334 11.1742 20.149 10.846 19.8208C10.5178 19.4926 10.3334 19.0475 10.3334 18.5834V15.0834C10.3334 14.6192 10.5178 14.1741 10.846 13.8459C11.1742 13.5177 11.6193 13.3334 12.0834 13.3334H17.9167C18.3809 13.3334 18.826 13.5177 19.1542 13.8459C19.4824 14.1741 19.6667 14.6192 19.6667 15.0834V18.5834Z" fill="#3E5196" />
      <path d="M15.0001 15.6666C14.8454 15.6666 14.697 15.7281 14.5876 15.8375C14.4782 15.9469 14.4167 16.0952 14.4167 16.25V17.4166C14.4167 17.5713 14.4782 17.7197 14.5876 17.8291C14.697 17.9385 14.8454 18 15.0001 18C15.1548 18 15.3032 17.9385 15.4126 17.8291C15.522 17.7197 15.5834 17.5713 15.5834 17.4166V16.25C15.5834 16.0952 15.522 15.9469 15.4126 15.8375C15.3032 15.7281 15.1548 15.6666 15.0001 15.6666Z" fill="#3E5196" />
    </svg>
  );
}
