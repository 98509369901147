import { statusesAPI } from "../API/api";

const SET_PROJECT_STATUSES = 'SET_PROJECT_STATUSES';
const SET_PROJECT_DATE_STATUSES = 'SET_PROJECT_DATE_STATUSES';
const SET_PROJECT_DATE_ATTRIBUTES = 'SET_PROJECT_DATE_ATTRIBUTES';

let initialState = {
  projectStatuses: [],
  dateStatuses: [],
  dateAttributes: [],
  isFetching: false
};

export const projectStatusesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROJECT_STATUSES:
      return {
        ...state,
        projectStatuses: action.projectStatuses,
      }
    case SET_PROJECT_DATE_STATUSES:
      return {
        ...state,
        dateStatuses: action.dateStatuses,
      }
    case SET_PROJECT_DATE_ATTRIBUTES:
      return {
        ...state,
        dateAttributes: action.dateAttributes,
      }
    default: return { ...state };
  }
}

const setProjectStatuses = (projectStatuses) => ({ type: SET_PROJECT_STATUSES, projectStatuses });
const setDateStatuses = (dateStatuses) => ({ type: SET_PROJECT_DATE_STATUSES, dateStatuses });
const setDateAttributes = (dateAttributes) => ({ type: SET_PROJECT_DATE_ATTRIBUTES, dateAttributes });

export const getProjectStatuses = (projectId) => async (dispatch) => {
  await statusesAPI.getProjectStatuses(projectId)
    .then(response => dispatch(setProjectStatuses(response.data)))
    .catch(err => console.log(err))
}
export const getProjectDateStatuses = (projectId) => async (dispatch) => {
  await statusesAPI.getProjectDateStatuses(projectId)
    .then(response => dispatch(setDateStatuses(response.data)))
    .catch(err => console.log(err))
}
export const getProjectDateAttributes = (projectId) => async (dispatch) => {
  await statusesAPI.getProjectDateAttributes(projectId)
    .then(response => dispatch(setDateAttributes(response.data)))
    .catch(err => console.log(err))
}
