import { positionsApi } from "../API/api";

const SET_POSITIONS = 'SET_POSITIONS';

let initialState = {
  positions: [],
  isFetching: false
};

export const usersPositionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_POSITIONS:
      return {
        ...state,
        positions: action.positions,
      }
    default: return { ...state };
  }
}

const setPositions = (positions) => ({ type: SET_POSITIONS, positions });

export const getPositions = (organizationId) => async (dispatch) => {
  await positionsApi.getPositions(organizationId)
    .then(response => dispatch(setPositions(response.data)))
    .catch(err => console.log(err))
}
