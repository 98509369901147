import React, { useEffect, useRef, useState } from 'react';
import styles from './emoji.module.css';
import classNames from 'classnames';
import EmojiIcon from '../../../../svgIcons/EmojiIcon';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

export default function Emoji(props) {
  const ref = useRef(null)

  const outsideHandleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setOpenEmoji(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', outsideHandleClick);
    return () => {
      document.removeEventListener('mouseup', outsideHandleClick);
    };
  }, [])

  const [openEmoji, setOpenEmoji] = useState(false)

  return (
    <div className={classNames('flex', styles.emojiWrap)}>
      <button
        onClick={() => setOpenEmoji(true)}
        type="button"
        className={classNames('flex', 'iconBtn')}
      >
        <EmojiIcon />
      </button>
      {
        openEmoji &&
        <div ref={ref} className={styles.emojiBox}>
          <Picker
            data={data}
            onEmojiSelect={e => props.emojiClick(e)}
            locale='ru'
            previewPosition='none'
            dynamicWidth={true}
            className={styles.hghg}
          />
        </div>
      }
    </div>
  );
}
