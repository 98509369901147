import classNames from "classnames";
import React, { useState } from "react";
import { socketFileApi } from "../../../../../../../../../../API/api";
import { socket } from "../../../../../../../../../../API/socket";
import CheckedIcon from "../../../../../../../../../../svgIcons/CheckedIcon";
import DeleteIcon from "../../../../../../../../../../svgIcons/DeleteIcon";
import HideIcon from "../../../../../../../../../../svgIcons/HideIcon";
import UploadIcon from "../../../../../../../../../../svgIcons/UploadIcon";
import FileCardList from "../../../../../../../../../common/FileCardList/FileCardList";
import TaskFilesPopupWrapper from "../../TaskPopups/TaskFilesPopup/TaskFilesPopupWrapper";
import styles from './taskfiles.module.css';

export default function TaskFiles(props) {

  const [isOpenFilesPopup, setIsFilesPopup] = useState(false);
  const [isAwait, setIsAwait] = useState(false);
  const [error, setError] = useState(null);
  const handleClickClosePopup = () => {
    setIsFilesPopup(false);
  }

  const uploadFiles = (files) => {
    setIsAwait(true);
    setError(null);

    socketFileApi.setTaskFiles(files)
      .then(res => {
        socket.emit('uploadFiles', {
          'taskId': props.taskId,
          'fileIds': res.data
        })
        setIsAwait(false);
      })
      .catch((err) => {
        setError(true)
        setIsAwait(false);
      })
    handleClickClosePopup()
  }

  const handleDeleteFile = ({item: item}) => {
    socket.emit('deleteTaskFile',
      {
        "fileId": item
      }
    )
    item.file === props.banner &&
      socket.emit('SetTaskBanner', {
        "task_id": props.taskId,
      })
  }
  const handleBanner = ({item: item}) => {
    socket.emit('SetTaskBanner', {
      "task_id": props.taskId,
      "attachment_id": item,
    })
  }

  const fileMenuDoc = [
    {
      id: 'file4',
      name: 'Удалить',
      icon: <DeleteIcon />,
      callBack: ({item: item}) => handleDeleteFile({ item: item })
    },
  ]
  const fileMenuImg = [
    {
      id: 'file1',
      name: 'Сделать обложкой',
      icon: <CheckedIcon />,
      callBack: ({item: item}) => handleBanner({ item: item })
    },
    {
      id: 'file2',
      name: 'Удалить',
      icon: <DeleteIcon />,
      callBack: ({item: item}) => {
        console.log(item)
        handleDeleteFile({ item: item })}
    },
  ]

  return (
    <div className={styles.filesWrap}>
      <div className={classNames('flex', 'blockTitleWrapper', styles.filesTitleWrap)}>
        <h2>Вложения</h2>
        {
          !props.readOnly &&
          <button
            className={classNames('flex', 'iconBtn')}
            onClick={() => setIsFilesPopup(true)}
          >
            <span className={'flex'}>
              <UploadIcon />
            </span>
            Загрузить файл
          </button>
        }
      </div>
      {
        (isAwait || error) &&
        <div>
          {isAwait && "Файлы загружаются..."}
          {error && "При загрузке файлов произошла ошибка"}
        </div>
      }
      {
        props.files?.length > 0 ?
          // <FilesList
          //   filesList={props.files}
          //   fileWithControls={true}
          //   insideParent={true}
          //   handleDeleteFile={handleDeleteFile}
          //   handleBanner={handleBanner}
          //   readOnly={props.readOnly}
          // />
          <FileCardList
            files={props.files}
            insideParent={true}
            fileMenuDoc={fileMenuDoc}
            fileMenuImg={fileMenuImg}
          />
          :
          <div className={styles.emptyFiles}>Файлы отсутствуют</div>
      }
      {
        isOpenFilesPopup &&
        <TaskFilesPopupWrapper
          forTasks={true}
          handleClickClose={handleClickClosePopup}
          withLinks={true}
          insideParent={true}
          uploadFiles={uploadFiles}
        />
      }
    </div>
  )
}
