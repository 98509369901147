import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteBoard, editBoard } from '../../../../../../redux/boardsListReducer';
import DeleteIcon from '../../../../../../svgIcons/DeleteIcon';
import FromArchiveIcon from '../../../../../../svgIcons/FromArchiveIcon';
import ConfirmWindow from '../../../../../common/ConfirmWindow/ConfirmWindow';
import styles from './archiveboardlist.module.css';
import ArchivedItem from './ArchivedItem/Archiveditem';

export default function ArchiveBoardList(props) {

  const ref = useRef(null)

  const outsideHandleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      props.closePopup(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', outsideHandleClick);
    return () => { }
  }, [])

  return (
    <div
      className={classNames('flex', 'shadow', styles.archivedList)}
      ref={ref}
    >
      {
        props.archivedBoards.map(item =>
          <ArchivedItem
            key={item.id}
            name={item.name}
            id={item.id}
            closePopup={props.closePopup}
            handleClickOpenConfirm={props.handleClickOpenConfirm}
            setBoardId={props.setBoardId}
          />
        )
      }
    </div>
  );
}
