import React from 'react';

export default function UploadIcon(props) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2013_62)">
        <path d="M16.6242 4.61333L13.7209 1.70833C12.6184 0.606667 11.1534 0 9.59592 0H5.83342C3.53591 0 1.66675 1.86917 1.66675 4.16667V15.8333C1.66675 18.1308 3.53591 20 5.83342 20H14.1667C16.4642 20 18.3334 18.1308 18.3334 15.8333V8.7375C18.3334 7.17833 17.7259 5.715 16.6242 4.61333ZM15.4459 5.7925C15.7109 6.05667 15.9351 6.35083 16.1167 6.6675H12.4992C12.0392 6.6675 11.6659 6.29333 11.6659 5.83417V2.21583C11.9826 2.3975 12.2767 2.62167 12.5417 2.88667L15.4451 5.79167L15.4459 5.7925ZM16.6667 15.8342C16.6667 17.2125 15.5451 18.3342 14.1667 18.3342H5.83342C4.45508 18.3342 3.33341 17.2125 3.33341 15.8342V4.16667C3.33341 2.78833 4.45508 1.66667 5.83342 1.66667H9.59592C9.73175 1.66667 9.86675 1.67333 10.0001 1.68583V5.83333C10.0001 7.21167 11.1217 8.33333 12.5001 8.33333H16.6476C16.6601 8.46667 16.6667 8.60167 16.6667 8.7375V15.8342ZM13.0892 13.4217C13.4151 13.7467 13.4151 14.2742 13.0892 14.6L11.7451 15.945C11.2642 16.4258 10.6317 16.6667 10.0001 16.6667C9.36841 16.6667 8.73592 16.4258 8.25508 15.945L6.91092 14.6C6.58508 14.2742 6.58508 13.7467 6.91092 13.4217C7.23675 13.0958 7.76341 13.0958 8.08925 13.4217L9.16675 14.4992V10.8342C9.16675 10.3742 9.53925 10.0008 10.0001 10.0008C10.4609 10.0008 10.8334 10.3742 10.8334 10.8342V14.4992L11.9109 13.4217C12.2367 13.0958 12.7634 13.0958 13.0892 13.4217Z" fill="#3E5196" />
      </g>
      <defs>
        <clipPath id="clip0_2013_62">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
