import { tasksWithAPI } from "../../API/api";
import { mapObj } from "../../utils/objectHelpers";

const SET_TIMERS = 'SET_TIMERS';
const CREATE_TIME_ITEM = 'CREATE_TIME_ITEM';
const STOP_TIME_ITEM = 'STOP_TIME_ITEM';
const DELETE_TIME_ITEM = 'DELETE_TIME_ITEM';

let initialState = {
  timers: [],
  isFetching: false
};

export const myTimersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TIMERS:
      return {
        ...state,
        timers: action.timers,
      }
    case CREATE_TIME_ITEM:
      return {
        ...state,
        timers: [...state.timers, action.newTimer]
      }
    case STOP_TIME_ITEM:
      return {
        ...state,
        timers: mapObj(state.timers, action.timerId, 'id', action.newObj)
      }
    case DELETE_TIME_ITEM:
      return {
        ...state,
        timers: state.timers.filter((el) => el.id !== action.timerId)
      }
    default: return { ...state };
  }
}

const setTimers = (timers) => ({ type: SET_TIMERS, timers });
const createTimer = (newTimer) => ({ type: CREATE_TIME_ITEM, newTimer });
const stopTimer = (timerId, newObj) => ({ type: STOP_TIME_ITEM, timerId, newObj });
const deleteTimer = (timerId) => ({ type: DELETE_TIME_ITEM, timerId });

export const getTimers = () => async (dispatch) => {
  await tasksWithAPI.getTimers()
    .then(response => dispatch(setTimers(response.data)))
    .catch(err => console.log(err))
}

// сюда айди таски
export const createTimerItem = (id) => async (dispatch) => {
  await tasksWithAPI.createTimer(id)
    .then(response => dispatch(createTimer(response.data)))
    .catch(err => console.log(err))
}

// сюда айди таски
export const stopTimerItem = (id) => async (dispatch) => {
  await tasksWithAPI.stopTimer(id)
    .then(response => dispatch(stopTimer(response.data.id, response.data)))
    .catch(err => console.log(err))
}

// сюда айди таймера
export const deleteTimerItem = (id) => async (dispatch) => {
  await tasksWithAPI.deleteTimer(id)
    .then(() => dispatch(deleteTimer(id)))
    .catch(err => console.log(err))
}
