import classNames from 'classnames';
import React, { useState } from 'react';
import { socket } from '../../../../../../../../API/socket';
import { hex2rgb } from '../../../../../../../../utils/hex2rgb';
import styles from './addtaskform.module.css';

export default function AddTaskForm(props) {

  const [taskDescr, setTaskDescr] = useState('');

  const clearForm = () => {
    setTaskDescr('');
    props.setIsOpenAddTask(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (taskDescr.trim().length > 0) {
      socket.emit('addTask',
        {
          columnId: props.colId,
          name: taskDescr.trim(),
        }
      )
      clearForm()
    } else {
      return
    }
  }

  const handleKeyDown = (e) => {
    if (e.code.includes('Enter') && e.shiftKey) {
      return
    }
    if (e.code.includes('Enter') && !e.shiftKey) {
      e.preventDefault()
      handleSubmit(e)
    }
  }

  return (
    <form
      onSubmit={handleSubmit}
      className={classNames('flex', 'contentBlock', styles.addTaskForm)}
      style={{ backgroundColor: `rgba(${hex2rgb(props.color || '#ffffff')}, .95)` }}
    >
      <div className={classNames('areaWrapper', styles.textWrapper)}>
        <p className={'hiddenText'}>
          {taskDescr} <br />
          текст
        </p>
        <textarea
          className={classNames('input', 'resizeArea')}
          name="task"
          placeholder='Введите название'
          onChange={(e) => {
            setTaskDescr(e.target.value)
          }}
          autoFocus={props.focusTask}
          onKeyDown={(e) => {
            handleKeyDown(e)
          }}
        >
        </textarea>
      </div>
      <div className={'popupBtnsWrapper'}>
        <button
          type='button'
          className={classNames('btn', 'btnTransparent', styles.smallbtn)}
          onClick={clearForm}
        >
          Отмена
        </button>
        <button
          disabled={!taskDescr.replace(/\s+/g, '').length}
          type='submit'
          className={classNames('btn', styles.smallbtn)}
        >
          Добавить
        </button>
      </div>
    </form>
  );
}
