import { projectItemAPI } from "../../API/api";

const SET_INFO = 'SET_INFO';
const SET_NEW_STATUS = 'SET_NEW_STATUS';
const SET_ARCHIVED = 'SET_ARCHIVED';
const SET_NEW_NAME = 'SET_NEW_NAME';
const SET_TEAM = 'SET_TEAM';
const SET_NEW_FILES = 'SET_NEW_FILES';

let initialState = {
  projectInfo: {},
  isFetching: false
};

export const projectItemReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_INFO:
      return {
        ...state,
        projectInfo: action.projectInfo,
      }
    case SET_NEW_STATUS:
      return {
        ...state,
        projectInfo: { ...state.projectInfo, status: action.status },
      }
    case SET_NEW_NAME:
      return {
        ...state,
        projectInfo: { ...state.projectInfo, name: action.name },
      }
    case SET_TEAM:
      return {
        ...state,
        projectInfo: { ...state.projectInfo, workers: action.team },
      }
    case SET_ARCHIVED:
      return {
        ...state,
        projectInfo: { ...state.projectInfo, archived: action.archived },
      }
    case SET_NEW_FILES:
      return {
        ...state,
        projectInfo: { ...state.projectInfo, attachments: action.files },
      }
    default: return { ...state };
  }
}

const setInfo = (projectInfo) => ({ type: SET_INFO, projectInfo });
const setStatus = (status) => ({ type: SET_NEW_STATUS, status });
const setName = (name) => ({ type: SET_NEW_NAME, name });
const setTeam = (team) => ({ type: SET_TEAM, team });
const setArchived = (archived) => ({ type: SET_ARCHIVED, archived });
const setFiles = (files) => ({ type: SET_NEW_FILES, files });

export const getProjectInfo = (id) => async (dispatch) => {
  await projectItemAPI.getProjectInfo(id)
    .then(response => dispatch(setInfo(response.data)))
    .catch(err => console.log(err))
}

export const editProject = (
  {
    id: id,
    name: name,
    manager: manager,
    client: client,
    workers: workers,
    price: price,
    description: description,
    start_date: start_date
  }
) => async (dispatch) => {
  return await projectItemAPI.editProjectInfo(
    {
      id: id,
      name: name,
      manager: manager,
      client: client,
      workers: workers,
      price: price,
      description: description,
      start_date: start_date
    })
    .then(response => {
      dispatch(setInfo(response.data));
      return response;
    })
    .catch((err) => {
      throw err;
    })
}

export const editProjectStatus = (
  {
    id: id,
    status: status,
  }
) => async (dispatch) => {
  return await projectItemAPI.editProjectStatus(
    {
      id: id,
      status: status,
    })
    .then(response => {
      dispatch(setStatus(response.data.status));
      return response;
    })
    .catch((err) => {
      throw err;
    })
}
export const editProjectArchived = (
  {
    id: id,
    archived: archived,
  }
) => async (dispatch) => {
  return await projectItemAPI.editProjectArchived(
    {
      id: id,
      archived: archived,
    })
    .then(response => {
      dispatch(setArchived(response.data.archived));
      return response;
    })
    .catch((err) => {
      throw err;
    })
}

export const editProjectName = (
  {
    id: id,
    name: name,
  }
) => async (dispatch) => {
  return await projectItemAPI.editProjectName(
    {
      id: id,
      name: name,
    })
    .then(response => {
      dispatch(setName(response.data.name));
      return response;
    })
    .catch((err) => {
      throw err;
    })
}

export const editProjectTeam = (
  {
    id: id,
    team: team,
  }
) => async (dispatch) => {
  return await projectItemAPI.editProjectTeam(
    {
      id: id,
      team: team,
    })
    .then(response => {
      dispatch(setTeam(response.data.workers));
      return response;
    })
    .catch((err) => {
      throw err;
    })
}

export const editProjectFiles = (
  {
    id: id,
    files: files,
  }
) => async (dispatch) => {
  await projectItemAPI.editProjectFiles(
    {
      id: id,
      files: files,
    })
    .then(response => {
      dispatch(setFiles(response.data.attachments));
      return response;
    })
    .catch((err) => {
      throw err;
    })
}
