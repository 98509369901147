import React from "react";
import { createPortal } from "react-dom";
import CheckItemPopup from "./CheckItemPopup";

export default function CheckItemPopupWrapper(props) {

  return (
    !props.insideParent ?
      createPortal((
        <CheckItemPopup
          handleClickClose={props.handleClickClose}
          handleAddChecklistItem={props.handleAddChecklistItem}
        />
      ), document.getElementById('modal_root'))
      :
      <CheckItemPopup
        handleAddChecklistItem={props.handleAddChecklistItem}
        handleClickClose={props.handleClickClose}
      />
  )
}
