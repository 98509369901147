import classNames from 'classnames';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getChatHistory, sendMessage } from '../../../../redux/Chat/chatReducer';
import { selectChat, selectLoadingChat } from '../../../../redux/Chat/chatSelector';
import Chat from './Chat';

export default function ChatContainer(props) {

  const location = useLocation();

  const [project, setProject] = useState(undefined)
  const [board, setBoard] = useState(undefined)
  const [task, setTask] = useState(undefined)
  const [defaultMessage, setDefaultMessage] = useState("")

  const chat = useSelector(selectChat);
  const loading = useSelector(selectLoadingChat);
  const dispatch = useDispatch();

  useEffect(() => {
    !location.pathname.includes('accept-invitation') && dispatch(getChatHistory())
  }, [dispatch, location])

  useEffect(() => {
    const path = location?.pathname;
    const hash = location.hash;

    if (path.includes('projects')) {
      const href = location.pathname;
      const step_1 = href.slice(1);
      const step_2 = step_1.slice(step_1.indexOf('/') + 1)

      setProject(step_2.slice(0, step_2.includes('/') ? step_2.indexOf('/') : step_2.length + 1))

      console.log("Проект -", step_2.slice(0, step_2.includes('/') ? step_2.indexOf('/') : step_2.length))
    } else {
      setProject(undefined)
    }

    if (path.includes('board')) {
      const href = location.pathname;

      console.log("Доска -", href.slice(href.lastIndexOf('/') + 1, href.length))
      setBoard(href.slice(href.lastIndexOf('/') + 1, href.length))
    } else {
      setBoard(undefined)
    }

    if (hash?.includes('task')) {
      console.log('Таска -', hash.slice(hash.indexOf('-') + 1, hash.length))
      setTask(hash.slice(hash.indexOf('-') + 1, hash.length))
    } else {
      setTask(undefined)
    }
  }, [location])

  const send = ({
    content: content,
  }) => {
    if (defaultMessage.trim().length) {
      dispatch(sendMessage({
        content: content,
        projectId: project,
        boardId: board,
        taskId: task
      }))
        .then(() => {
          console.log('Последействие')
          setDefaultMessage('')
        })
    } else {
      return
    }
  }

  return (
    <>
      <Chat
        chat={chat}
        send={send}
        defaultMessage={defaultMessage}
        setDefaultMessage={setDefaultMessage}
        loading={loading}
      />
    </>
  );
}
