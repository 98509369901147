import classNames from 'classnames';
import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectMe } from '../../../../../redux/authSelectors';
import CloseIcon from '../../../../../svgIcons/CloseIcon';
import SendIcon from '../../../../../svgIcons/SendIcon';
import styles from './fullchat.module.css';
import MessageItem from './MessageItem/MessageItem';
import loader from '../../../../../assets/images/chat/load.gif'


export default function FullChat(props) {

  const me = useSelector(selectMe);

  const ref = useRef();

  const handleKeyDown = (e) => {
    if (e.code.includes('Enter') && e.shiftKey) {
      return
    }
    if (e.code.includes('Enter') && !e.shiftKey) {
      e.preventDefault()
      props.send({ content: props.defaultMessage })
    }
  }

  useEffect(() => {
    if (props.chat?.length) {
      ref.current.scrollTop = ref.current.scrollHeight
    }
    return () => { }
  }, [ref, props.loading])

  return (
    <div className={classNames('contentBlock', 'shadow', 'flex', 'chat', styles.fullchat)}>
      <div className={classNames('flex', styles.header)}>
        <h3 className={styles.title}>Ваш надежный помощник</h3>
        <button
          className={classNames('iconBtn', 'flex')}
          onClick={() => props.setIsFull(false)}
        >
          <CloseIcon />
        </button>
      </div>
      <div className={classNames('flex', styles.body)} ref={ref}>
        {
          props.chat.map(item =>
            <MessageItem
              key={item?.id}
              author={item?.sender_type}
              content={item?.content}
              my={item?.sender_type === 'user'}
              ai={item?.sender_type === 'ai'}
              system={item?.sender_type === 'system'}
              support={item?.sender_type === 'support'}
              time={item.sent_at}
              me={me}
            />
          )
        }
        {
          props.loading &&
          <div className={styles.loader}>
            {
              loader ?
                <img src={loader} alt='preloader' />
                :
                "Ожидайте"
            }
          </div>
        }
      </div>
      <div className={styles.footer}>
        <form className={classNames('flex', styles.form)}>
          <div className={classNames('inputWrapper', styles.inputWrapper)}>
            <textarea
              value={props.defaultMessage}
              onChange={(e) => props.setDefaultMessage(e.target.value)}
              className={classNames('input', styles.input)}
              placeholder="Введите сообщение"
              onKeyDown={(e) => {
                handleKeyDown(e)
              }}
            >
            </textarea>
          </div>
          <button
            type='button'
            onClick={() => {
              props.send({ content: props.defaultMessage })
            }}
            className={classNames('flex', 'iconBtn', styles.sendBtn)}
          >
            <SendIcon />
          </button>
        </form>
      </div>
    </div>
  );
}
