import classNames from 'classnames';
import React from 'react';
import { createPortal } from 'react-dom';
import { generateKey } from '../../../../../utils/generateKey';
import TaskItem from './TaskItem/TaskItem';
import styles from './tasklist.module.css';

export default function TaskList(props) {



  return createPortal((
      <div className={classNames('contentBlock', 'shadow', styles.list)}>
        {
          props.tasks?.length > 0 ?
          props.tasks.map(item =>
            <TaskItem
              key={item.id}
              item={item}
              timer={props.timers.find(tim => +tim?.task === +item.id)}
            />
          )
          :
          <div>Назначенных на Вас задач нет.</div>
        }
      </div>
    ), document.getElementById('modal_root')
  );
}
