import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { socket } from '../../../../../../../../../../../API/socket';
import styles from './colslist.module.css';

export default function ColsList(props) {

  const refList = useRef(null)

  const outsideHandleClick = (e) => {
    if (refList.current && !refList.current.contains(e.target)) {
      props.handleClickClose(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', outsideHandleClick);
    return () => { }
  }, [])

  const handleMove = (id) => {
    socket.emit('CopyTask',
      {
        "taskId": props.taskId,
        "columnId": id,
        "action": "move",
      })
    props.handleClickClose()
  }

  return (
    <div className={classNames(
      'flex',
      'shadow',
      styles.list,
    )} ref={refList}>
      {props.columns?.map(item =>
        <button
          key={item.id}
          className={classNames('flex', 'iconBtn', styles.statusItem)}
          onClick={() => handleMove(item.id)}
        >
          {item.name}
        </button>
      )}
    </div>
  );
}
