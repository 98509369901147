import React, { useEffect, useRef, useState } from 'react';
import styles from './commentfilemenu.module.css';
import classNames from 'classnames';

export default function CommentFileMenu(props) {

  const refList = useRef(null)

  const outsideHandleClick = (e) => {
    if (refList.current && !refList.current.contains(e.target)) {
      props.handleClickClose();
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', outsideHandleClick);
    return () => {
      document.removeEventListener('mouseup', outsideHandleClick);
    };
  }, [])

  const fileTypes = [
    {
      name: 'Фотография',
      id: 'photo',
    },
    {
      name: 'Видео',
      id: 'video',
    },
    {
      name: 'Аудио',
      id: 'audio',
    },
    {
      name: 'Файл',
      id: 'file',
    }
  ];

  return (
      <div className={classNames(styles.typesWrapper, 'shadow', 'flex')} ref={refList}>
        {
          fileTypes.map((item, index) =>
            <button
              key={index}
              onClick={() => {
                props.setType(item.id)
                props.handleClickOpenAdd()
                props.handleClickClose()
              }}
              className={classNames('iconBtn', styles.typeItem)}
            >
              {item.name}
            </button>
          )
        }
      </div>

  );
}
