import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import styles from './filecardmenu.module.css';

export default function FileCardMenu(props) {

  const refList = useRef(null)

  const outsideHandleClick = (e) => {
    if (refList.current && !refList.current.contains(e.target)) {
      props.setIsOpenMenu(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', outsideHandleClick);
    return () => document.removeEventListener('mouseup', outsideHandleClick);
  }, [])

  return (
    <div className={classNames(classNames('flex', 'shadow', styles.menu))} ref={refList}>
      {props.fileMenu.map(item =>
        <button
          key={item.id}
          className={classNames('flex', 'iconBtn', 'menuBtn')}
          onClick={() => item.callBack({ arr: props.files, item: props.item })}
        >
          <div className={classNames('flex', styles.menuIcon)}>
            {item.icon}
          </div>
          {item.name}
        </button>
      )}
    </div>
  );
}
