import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useSelector } from "react-redux";
import { socket } from "../../../../../../../../../API/socket";
import { selectColumns } from "../../../../../../../../../redux/BoardPage/сolumnSelector";
import DotsIcon from "../../../../../../../../../svgIcons/DotsIcon";
import Banner from "./Banner/Banner";
import styles from './taskcard.module.css';
import TaskCardUsers from "./TaskCardUsers/TaskCardUsers";
import TaskCommentContainer from "./TaskChat/TaskCommentsContainer";
import TaskCheckList from "./TaskCheckList/TaskCheckList";
import TaskDescrField from "./TaskDescrField/TaskDescrField";
import TaskFiles from "./TaskFiles/TaskFiles";
import TaskMeta from "./TaskMeta/TaskMeta";
import TaskSideBar from "./TaskSideBar/TaskSideBar";
import TaskTags from "./TaskTags/TaskTags";

export default function TaskCard(props) {

  const ref = useRef(null)

  const outsideHandleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target) && !e.target.closest('.chat')) {
      props.handleClickClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', outsideHandleClick);
    return () => {
      document.removeEventListener('mouseup', outsideHandleClick);
    }
  }, [])

  const cols = useSelector(selectColumns);

  const [editName, setEditName] = useState(!props.task.name || false);
  const [taskName, setTaskName] = useState(props.task.name);
  const [width, setWidth] = useState(window.innerWidth)
  const [isOpenControls, setIsOpenControls] = useState(false);

  const [columns, setColumns] = useState(cols?.columns?.map(item => {
    return { name: item.name, id: item.id }
  }).filter(item =>
    item.id !== props.colId
  ))

  useEffect(() => {
    if (!props.task.name.trim()) {
      setEditName(true)
    }
  }, [props.task.name])

  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth)
    })
    return () => { }
  }, [])

  const onChangeName = () => {
    taskName.trim() && setEditName(false);
    (taskName != props.task.name) && socket.emit('ChangeTaskName', { 'taskId': props.task.id, 'name': taskName })
  }

  return createPortal((
    <div
      className={classNames('flex', 'popup', styles.cardPopup)}
      onMouseDown={(e) => {
        e.stopPropagation()
      }}
    >
      <div className={classNames('popupWrapper')}>
        <div className={classNames('popupWindow', 'shadow', styles.taskCard)} ref={ref}>
          <div className={classNames('flex', styles.top)}>
            <div className={classNames('flex', styles.topLeft, props.readOnly && styles.withoutBorder)}>
              {(props.task.img || props.task.img_url) &&
                <Banner
                  img={props.task.img || props.task.img_url}
                  taskId={props.task.id}
                  readOnly={props.readOnly}
                />
              }
              <TaskTags
                taskId={props.task.id}
                tags={props.task.labels}
                boardId={props.boardId}
                readOnly={props.readOnly}
              />
              {
                !editName ?
                  <div>
                    <pre
                      className={styles.taskName}
                      onClick={() => {
                        !props.readOnly &&
                        setEditName(true)}
                      }
                    >
                      {props.task.name}
                    </pre>
                  </div>
                  :
                  <div className={classNames('areaWrapper', styles.textWrapper)}>
                    <p className={'hiddenText'}>
                      {taskName} <br />
                      текст
                    </p>
                    <textarea
                      autoFocus={taskName}
                      onFocus={(e) => e.target.setSelectionRange(taskName.length, taskName.length)}
                      className={classNames('input', 'resizeArea')}
                      name="task"
                      placeholder='Введите название'
                      defaultValue={taskName}
                      onChange={(e) => setTaskName(e.target.value)}
                      onBlur={onChangeName}
                    >
                    </textarea>
                  </div>
              }
              <TaskMeta
                colName={props.column}
                date={props.task.deadline}
                taskId={props.task.id}
                columns={columns}
                readOnly={props.readOnly}
              />
              <TaskDescrField
                taskId={props.task.id}
                descr={props.task.description}
                readOnly={props.readOnly}
              />
              <TaskCardUsers
                project={props.project}
                taskId={props.task.id}
                users={props.task.performers}
                readOnly={props.readOnly}
              />
              {
                props.task.checklists?.items &&
                <TaskCheckList
                  checkList={props.task.checklists}
                  taskId={props.task.id}
                  readOnly = {props.readOnly}
                />
              }
              <TaskFiles
                files={props.task.attachments}
                taskId={props.task.id}
                banner={props.task.img}
                readOnly={props.readOnly}
              />
            </div>
            {
              (width <= 768 && !props.readOnly) &&
              <button
                onClick={() => setIsOpenControls(!isOpenControls)}
                className={classNames('flex', 'iconBtn', 'dotsBtn', styles.controlBtn)}
              >
                <DotsIcon />
              </button>
            }
            {
              (width > 768 && !props.readOnly) ?
                <div className={styles.controls}>
                  <TaskSideBar
                    project={props.project}
                    task={props.task}
                    colId={props.colId}
                    banner={props.task.img || props.task.img_url}
                    boardId={props.boardId}
                  />
                </div>
                : isOpenControls ?
                  <div className={styles.controls}>
                    <TaskSideBar
                      project={props.project}
                      task={props.task}
                      banner={props.task.img || props.task.img_url}
                      boardId={props.boardId}
                    />
                  </div>
                  :
                  null
            }
          </div>
          <div className={classNames(styles.bottom)}>
            <TaskCommentContainer
              comments={props.task.comments}
              parentWindow={ref.current}
              colId={props.colId}
              targetId={props.task.id}
              readOnly={props.readOnly}
            />
          </div>
        </div>
      </div>
    </div >
  ), document.getElementById('modal_root'))
}
