
import React from 'react';
import styles from './previewfiles.module.css';
import classNames from 'classnames';
import DeleteIcon from '../../../svgIcons/DeleteIcon';
import FileIcon from '../../../svgIcons/FileIcon';
import { editLongFileName } from '../../../utils/fileNameEditor';


export default function PreviewFiles(props) {

  return (

    <div className={classNames('flex', styles.previewFiles)}>
      <div className={classNames('flex', styles.photoVideo)}>
        {props.files.filter(item => item.type.includes('video') || item.type.includes('image')).map((item) =>
          <div className={classNames('flex', styles.previewItem)}>
            <div className={styles.previewImage}>
              {
                item.type.includes('image') ?
                  <img src={URL.createObjectURL(item)} alt="" />
                  :
                  item.type.includes('video') ?
                    <>
                      <video src={URL.createObjectURL(item)} />
                      <p className={styles.videoInit}>Вид</p>
                    </>
                    :
                    <FileIcon />
              }
              <button
                type="button"
                className={classNames('iconBtn', 'flex', styles.delbtn)}
                onClick={() => props.handleDeleteFile(props.files, item)}
              >
                <DeleteIcon />
              </button>
            </div>
            {
              (!item.type.includes('image') && !item.type.includes('video')) &&
              <p className={styles.previewName}>
                {editLongFileName(item.name)}
              </p>
            }
          </div>
        )}
      </div>
      <div className={classNames('flex', styles.files)}>
        {props.files.filter(item => !item.type.includes('video') && !item.type.includes('image') && !item.type.includes('audio')).map((item) =>
          <div className={classNames('flex', styles.previewItem)}>
            <div className={classNames(styles.previewImage, styles.previewImageFile)}>
              <FileIcon />
              <button
                type="button"
                className={classNames('iconBtn', 'flex', styles.delbtn)}
                onClick={() => props.handleDeleteFile(props.files, item)}
              >
                <DeleteIcon />
              </button>
            </div>
            <p className={styles.previewName}>
              {editLongFileName(item.name)}
            </p>
          </div>
        )}
      </div>
      <div className={classNames('flex', styles.files)}>
        {props.files.filter(item => item.type.includes('audio')).map((item) =>
          <div className={classNames('flex', styles.previewItem)}>
            <div className={classNames(styles.previewImage, styles.previewImageAudio)}>
              <FileIcon />
            </div>
            <p className={styles.previewName}>
              {editLongFileName(item.name)}
            </p>
            <button
              type="button"
              className={classNames('iconBtn', 'flex')}
              onClick={() => props.handleDeleteFile(props.files, item)}
            >
              <DeleteIcon />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
