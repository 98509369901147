
import React, { useState } from 'react';
import styles from './commentaddfiles.module.css';
import classNames from 'classnames';
import CommentAddFileWrapper from './CommentAddFileWrapper';
import UploadIcon from '../../../../svgIcons/UploadIcon';
import CommentFileMenu from './CommentFileMenu/CommentFileMenu';

export default function CommentAddFiles(props) {

  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenAddFile, setIsOpenAddFile] = useState(false);
  const [type, setType] = useState(false);

  const handleClickOpen = () => {
    setIsOpenMenu(true)
  }
  const handleClickClose = () => {
    setIsOpenMenu(false)
  }

  const handleClickOpenAdd = () => {
    setIsOpenAddFile(true)
    document.body.classList.add('modal-show');
  }
  const handleClickCloseAdd = () => {
    setIsOpenAddFile(false)
    document.body.classList.remove('modal-show');
  }

  return (
    <div className={classNames('flex', styles.uploadWrapper)}>
      <button
        onClick={handleClickOpen}
        type="button"
        className={classNames('flex', 'iconBtn', styles.upload)}
      >
        <UploadIcon />
      </button>
      {props.files.length > 0 &&
        <div className={styles.count}>
          {props.files.length}
        </div>
      }
      {
        isOpenMenu &&
        <CommentFileMenu
          handleClickClose={handleClickClose}
          handleClickOpenAdd={handleClickOpenAdd}
          setType={setType}
        />
      }
      {
        isOpenAddFile &&
        <CommentAddFileWrapper
          handleClickClose={handleClickCloseAdd}
          files={props.files}
          setFiles={props.setFiles}
          insideParent={props.insideParent}
          type={type}
        />
      }
    </div>
  );
}
